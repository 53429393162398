import React from 'react';
import '../style.scss';
import { connect } from 'react-redux';
import * as Actions from '../actions';
import { bindActionCreators } from "redux";
import parseQueryParams from '../../../utils/QueryParamsParser';
import Spinner from '../../../components/Spinner';
import ErrorToast from '../../../components/ErrorToast';

class PaymentMerchant extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            queryParams: null,
        };
    }

    componentDidMount() {
        this.getJsonFromUrl();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.queryParams != prevState.queryParams) {
            this.fetchPaymentData();
        }
        if (this.props.isFetching != prevProps.isFetching && !this.props.isFetching && !this.props.error && this.props.paymentResponse && this.props.paymentResponse.gateway_url && this.props.paymentResponse.data) {
            document.getElementById('js-payment-merchant').submit();
        }
    }

    fetchPaymentData = () => {
        this.props.actions.fetchAddMoneyData(this.state.queryParams);
    }

    getJsonFromUrl = () => {
        const result = parseQueryParams(this.props.location.search);
        this.setState({
            queryParams: result
        }, () => this.fetchPaymentData());
    }

    render() {
        const { paymentResponse, isFetching, error } = this.props;
        return (
            <div className="Container">
                {isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    :
                    error ?
                        <ErrorToast error={error} />
                        :
                        paymentResponse && paymentResponse.gateway_url && paymentResponse.data ?
                            <form action={paymentResponse.gateway_url} id="js-payment-merchant" method='POST'>
                                {Object.entries(paymentResponse.data).map(([key, value]) => (
                                    <input type="hidden" name={key} value={value} />
                                ))}
                                <input type="submit" value="submit" style={{ display: "none" }} />
                            </form>
                            : null
                }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
};

const mapStateToProps = state => ({
    paymentResponse: state.paymentReducer.paymentData.data,
    isFetching: state.paymentReducer.paymentData.isFetching,
    error: state.paymentReducer.paymentData.error
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMerchant);