import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {makeStyles} from '@material-ui/core/styles';

const useStyles1 = makeStyles(theme => ({
    error: {
        // backgroundColor: theme.palette.error.light,
    },
    closeIcon: {
        fontSize: 20,
    },
    errorIcon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MySnackbarContentWrapper(props) {
    const classes1 = useStyles1();
    const { errorText, handleClose } = props;

    return (
        <SnackbarContent
            className={classes1.error}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes1.message}>
                    <ErrorIcon className={classes1.errorIcon} />
                    {errorText}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
                    <CloseIcon className={classes1.closeIcon} />
                </IconButton>,
            ]}
        />
    );
}

export default class ErrorToast extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            open: false
        };
        this.handleClose = this.handleClose.bind(this);
    }
    // componentDidMount() {
    //     const { error } = this.props;
    //     if(error && error.timestamp && error.text) {
    //         this.setState({ errorText: error.text, open: true });
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { error } = this.props;
        if((!prevProps.error && error && error.timestamp) ||
            (prevProps.error && !prevProps.error.timestamp && error && error.timestamp) ||
            (error && error.timestamp && (error.timestamp > prevProps.error.timestamp) && error.text)) {
                this.setState({errorText: error.text, open: true});
        }
    }
    handleClose() {
        this.setState({ open: false });
    }
    render() {

        const { errorText, open } = this.state;
        return (
            <div>
                <Snackbar
                    style={this.props.style || {}}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: this.props.horizontalPosition || 'right',
                    }}
                    open={open}
                    autoHideDuration={this.props.updateDuration || 3000}
                    onClose={this.handleClose}
                >
                    <MySnackbarContentWrapper errorText={errorText} handleClose={this.handleClose}/>
                </Snackbar>
            </div>
        );
    }
}