import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi'

export function saveReport(payload) {
    return {
        type: actionTypes.SAVE_REPORT,
        payload: payload
    }
}

export function startFetchingReport() {
    return {
        type: actionTypes.START_FETCHING_REPORT,
    }
}

export function errorFetchingReport(error) {
    return {
        type: actionTypes.ERROR_FETCHING_REPORT,
        payload: error
    }
}

export function fetchReport(params) {

    return (dispatch) => {
        dispatch(startFetchingReport());
        RESTApi.GET('/owner_ms/api/v1/vas/sales/web/advanced_reports/get_pitch', params, (data) => {
            if (data)
                dispatch(saveReport(data))
        }, (error) => {
            dispatch(errorFetchingReport(error))
        })
    }
}

export function saveSubscribedReport(payload) {
    return {
        type: actionTypes.SAVE_SUBSCRIBED_REPORT,
        payload: payload
    }
}

export function startSubscribingReport() {
    return {
        type: actionTypes.START_SUBSCRIBING_REPORT,
    }
}

export function errorSubscribingReport(error) {
    return {
        type: actionTypes.ERROR_SUBSCRIBING_REPORT,
        payload: error
    }
}

export function subscribeReport(params, data) {

    return (dispatch) => {
        dispatch(startSubscribingReport())
        RESTApi.POST('/owner_ms/api/v1/vas/sales/web/advanced_reports/create', params, data, (data) => {
            if (data)
                dispatch(saveSubscribedReport(data))
        }, (error) => {
            dispatch(errorSubscribingReport(error))
        })
    }
}
