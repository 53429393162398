import * as actionTypes from './actionTypes';
import { WebsiteState as initialState } from './state';

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SAVE_WEBSITE:
            return {
                ...state,
                websiteData: {
                    data: action.payload.data,
                    error: false,
                    isFetching: false
                }
            }

        case actionTypes.START_FETCHING_WEBSITE:
            return {
                ...state,
                websiteData: {
                    ...state.websiteData,
                    isFetching: true,
                    error: false
                }
            }

        case actionTypes.ERROR_FETCHING_WEBSITE:
            return {
                ...state,
                websiteData: {
                    ...state.websiteData,
                    isFetching: false,
                    error: action.payload
                    // error: false,
                    // data: webData.data
                }
            }

        case actionTypes.START_SUBSCRIBING_WEBSITE:
            return {
                ...state,
                websiteSubscription: {
                    ...state.websiteSubscription,
                    isFetching: true,
                    error: false,
                }
            }

        case actionTypes.ERROR_SUBSCRIBING_WEBSITE:
            return {
                ...state,
                websiteSubscription: {
                    ...state.websiteSubscription,
                    isFetching: false,
                    error: action.payload,
                },
            }

        case actionTypes.SAVE_SUBSCRIBED_WEBSITE:
            return {
                ...state,
                websiteSubscription: {
                    data: action.payload,
                    error: false,
                    isFetching: false,
                }
            }

        default: return state

    }
}

export default reducer