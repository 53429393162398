export const RankingState = {
    rankingData: {
      data: {},
      isFetching: false,
      error: false
    },
    rankingSubscription: {
      data: {},
      isFetching: false,
      error: false,
    }
  }