import axios from 'axios';
import * as apiConstants from '../constants/apiConstants';

const baseURL = apiConstants.API_HOST;

const GET = (url, params, successCB, errorCb, headers) => {
    let config = {
        params: {
            ...params
        },
        headers: {
            ...headers,
            locale: apiConstants['locale']
        }
    };
    if (process.env.NODE_ENV === 'development') {
        console.log(baseURL + url);
        console.log(config);
    }
    axios.get(baseURL + url, config).then((response) => {
        if (process.env.NODE_ENV === 'development') {
            console.log("API Response: ", response);
        }
        if (successCB)
            successCB(response.data)
    }).catch((error) => {
        if (process.env.NODE_ENV === 'development') {
            console.log("Error Response: ", error.response);
        }
        if (errorCb) {
            if (error.response && error.response.data) {
                errorCb(error.response.data)
            } else
                errorCb(error.message)
        }
    });
};

const POST = (url, params, data, successCB, errorCb, headers) => {
    let config = {
        params: {
            ...params
        },
        headers: {
            ...headers,
            locale: apiConstants['locale']
        }
    };
    if (process.env.NODE_ENV === 'development') {
        console.log(baseURL + url);
        console.log(config);
        console.log(data);
    }

    axios.post(
        baseURL + url,
        data,
        config
    ).then((response) => {
        if (process.env.NODE_ENV === 'development') {
            console.log("API Response: ", response);
        }
        if (successCB)
            successCB(response.data)
    }).catch((error) => {
        if (process.env.NODE_ENV === 'development') {
            console.log("Error Response: ", error.response);
        }
        if (error.response && error.response.data) {
            errorCb(error.response.data)
        } else
            errorCb(error.message)
    });

};

const PUT = (url, params, data, successCB, errorCb, headers) => {
    let config = {
        params: {
            ...params
        },
        headers: {
            ...headers,
            locale: apiConstants['locale']
        }
    };
    if (process.env.NODE_ENV === 'development') {
        console.log(baseURL + url);
        console.log(config);
        console.log(data);
    }

    axios.put(
        baseURL + url,
        data,
        config
    ).then((response) => {
        if (process.env.NODE_ENV === 'development') {
            console.log("API Response: ", response);
        }
        if (successCB)
            successCB(response.data)
    }).catch((error) => {
        if (process.env.NODE_ENV === 'development') {
            console.log("Error Response: ", error.response);
        }
        if (error.response && error.response.data) {
            errorCb(error.response.data)
        } else
            errorCb(error.message)
    });

};

export default {
    GET,
    POST,
    PUT
}