import React from 'react';
import './style.css';
import { IoIosCheckmark } from 'react-icons/io';
import { IconContext } from "react-icons";

export default class RequestConfirmation extends React.PureComponent {
    render() {
        // eslint-disable-next-line
        const {data} = this.props;
        return (
            <div className="RequestConfirmationContainer" >
                <div className="Checkmark" >
                    <IconContext.Provider value={{ color: "white", size: "2.6em" }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IoIosCheckmark />
                    </IconContext.Provider>
                </div>
                <div className="RequestReceivedText">
                    {"Your request has been successfully received"}
                </div>
                <div className="ThanksText">
                    {"Thank you for showing your interest in this program. Our executive will get in touch with you in the next 24 hours."}
                </div>
                <div className="RequestTextContainer">
                    <div className="RequestText">
                        {data.request_info ? data.request_info : ""}
                    </div>
                </div>
            </div>
        );
    }
}
