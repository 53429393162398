import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi'

export function saveWebsite(payload) {
    return {
        type: actionTypes.SAVE_WEBSITE,
        payload: payload
    }
}

export function startFetchingWebsite() {
    return {
        type: actionTypes.START_FETCHING_WEBSITE,
    }
}

export function errorFetchingWebsite(error) {
    return {
        type: actionTypes.ERROR_FETCHING_WEBSITE,
        payload: error
    }
}

export function fetchWebsite(params) {

    return (dispatch) => {
        dispatch(startFetchingWebsite());
        RESTApi.GET('/owner_ms/api/v1/vas/sales/web/website_builder/get_pitch', params, (data) => {
            if (data)
                dispatch(saveWebsite(data))
        }, (error) => {
            dispatch(errorFetchingWebsite(error))
        })
    }
}

export function saveSubscribedWebsite(payload) {
    return {
        type: actionTypes.SAVE_SUBSCRIBED_WEBSITE,
        payload: payload
    }
}

export function startSubscribingWebsite() {
    return {
        type: actionTypes.START_SUBSCRIBING_WEBSITE,
    }
}

export function errorSubscribingWebsite(error) {
    return {
        type: actionTypes.ERROR_SUBSCRIBING_WEBSITE,
        payload: error
    }
}

export function subscribeWebsite(params, data) {

    return (dispatch) => {
        dispatch(startSubscribingWebsite())
        RESTApi.POST('/owner_ms/api/v1/vas/sales/web/website_builder/create', params, data, (data) => {
            if (data)
                dispatch(saveSubscribedWebsite(data))
        }, (error) => {
            dispatch(errorSubscribingWebsite(error))
        })
    }
}
