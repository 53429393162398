import React from 'react';
import _ from 'lodash';
import './style.scss';
import { connect } from 'react-redux';
import PaymentRender from './render';
import { bindActionCreators } from 'redux';
import * as Actions from './actions';
import { fetchBoletoFormData } from '../Boleto/actions';
import parseQueryParams from '../../utils/QueryParamsParser';
import Base64 from '../../utils/base64';
import ReactGA from 'react-ga';
import gaConstants from '../../constants/gaConstants';
import { getNestedValue } from '../../utils/objectNesting';
import { getPaymentData } from '../../utils/getPaymentData';
import { GATEWAY_LIST } from './constant';

class PaymentScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    const result = parseQueryParams(props.location.search);
    const paymentObj = getPaymentData(result['payment_data'], result['page_mode']);
    const decodedOtherParams = Base64.atob(result['other_params']).split(':')
    this.state = {
      queryParams: result,
      isHms: _.get(result, 'is_hms', 'false') === 'true',
      pageMode: result['page_mode'],
      paymentValue: paymentObj['paymentValue'],
      buttonDisabled: isNaN(paymentObj['paymentValue']),
      recharge_type: paymentObj['recharge_type'],
      hotel_id: paymentObj['hotel_id'],
      paymentType: paymentObj['paymentType'],
      currencySymbol: paymentObj['currencySymbol'] || '',
      offer_id: decodedOtherParams[0],
      error: {
        text:"",
        timestamp: new Date()
      }
    };
    this.setPaymentValue = this.setPaymentValue.bind(this);
  }

  componentDidMount() {
    const metaData = getNestedValue(this.props, 'screenMetaData.data', 'object');
    const payment_data = getNestedValue(this.state, 'queryParams.payment_data');

    if (_.isEmpty(metaData)) {
      this.props.actions.fetchScreenMetadata({ features: 'oyo-secure-web' });
    }

    if (payment_data) {
      this.props.actions.fetchGatewayModeData({
        page_mode: this.state.pageMode,
        payment_data
      });
    }

    ReactGA.pageview(gaConstants.pages.SECURE);
  }

  componentDidUpdate(prevProps) {
    const prevGatewayData = prevProps['otherGatewayData']['data'];
    const {
      otherGatewayData: { data: gatewayData, gateway }
    } = this.props;

    if (!_.isEqual(prevGatewayData, gatewayData) && gateway == GATEWAY_LIST['xendit']) {
      const gateway_url = getNestedValue(gatewayData, 'data.payment_data.response.gateway_url');
      if (gateway_url) {
        window.location.href = gateway_url;
      }
    }
  }

  setPaymentValue(val) {
    var decodedHashKey = Base64.atob(this.state.queryParams.payment_data).split(':');
    this.state.queryParams.page_mode == 'RECON_INDIA' ? (decodedHashKey[0] = val) : (decodedHashKey[1] = val);
    var newPaymentData = Base64.btoa(decodedHashKey.join(':'));
    if (this.state.paymentValue != val)
      this.setState({
        paymentValue: val ? val : '',
        queryParams: {
          ...this.state.queryParams,
          payment_data: newPaymentData
        }
      });
    if (!val && !this.state.buttonDisabled) this.setState({ buttonDisabled: true });
    else if (val && this.state.buttonDisabled) this.setState({ buttonDisabled: false });
  }

  setErrorToast = (val) => {
    this.setState({
      error: {
      text: val,
      timestamp: new Date()
    }}) 
  }

  initiatePayment = payment_gateway => {
    const { paymentValue, queryParams } = this.state;

    const payment_data = getNestedValue(queryParams, 'payment_data', 'string');
    const page_mode = getNestedValue(queryParams, 'page_mode', 'string');
    const source = getNestedValue(queryParams, 'source') || 'others';

    const queryObj = {
      amount: paymentValue,
      payment_data,
      page_mode,
      source,
      payment_gateway,
      offer_id: this.state.offer_id
    };

    this.props.actions.fetchAddMoneyData(queryObj, this.toast, this.setErrorToast);
  };

  render() {
    const { paymentData, screenMetaData, history, location, gatewayData, actions, otherGatewayData, hotelInfoData } =
      this.props;
    const {
      paymentValue,
      buttonDisabled,
      queryParams,
      recharge_type,
      hotel_id,
      paymentType,
      currencySymbol,
      pageMode
    } = this.state;

    const source = getNestedValue(queryParams, 'source') || 'others';

    return (
      <PaymentRender
        isHms={this.state.isHms}
        history={history}
        paymentValue={paymentValue}
        setPaymentValue={this.setPaymentValue}
        disabled={buttonDisabled}
        paymentUserData={queryParams && queryParams.payment_data ? queryParams.payment_data : ''}
        recharge_type={recharge_type}
        screenMetaData={screenMetaData}
        hotel_id={hotel_id}
        paymentType={paymentType}
        initiatePayment={this.initiatePayment}
        paymentData={paymentData}
        location={location}
        gatewayData={gatewayData}
        hotelInfoData={hotelInfoData}
        source={source}
        otherGatewayData={otherGatewayData}
        fetchOtherGatewayData={actions['fetchOtherGatewayData']}
        currencySymbol={currencySymbol}
        pageMode={pageMode}
        isAmountFieldUnEditable={this.state.isAmountFieldUnEditable}
        error={this.state.error}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...Actions,
        fetchOtherGatewayData: fetchBoletoFormData
      },
      dispatch
    )
  };
};

const mapStateToProps = state => ({
  paymentData: state.paymentReducer.paymentData,
  screenMetaData: state.paymentReducer.screenMetaData,
  gatewayData: state.paymentReducer.gatewayData,
  otherGatewayData: state.boletoReducer.formData,
  hotelInfoData: state.paymentReducer.hotelInfoData
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);
