import React, { Component } from "react";
import "./style.scss";
import image from "../../assets/images/success-logo.svg";
import { Table } from "antd";
import { connect } from "react-redux";
import { getNestedValue } from "../../utils/objectNesting";
import { epochToDate } from "../../utils/DateUtils";

const rowLabels = {
  date: "SECURE_WEB_LABEL_DATE",
  transaction_id: "SECURE_WEB_LABEL_TRANSACTION_ID",
  payment_mode: "SECURE_WEB_LABEL_PAYMENT_MODE",
};

const columns = (screenLabels) => [
  {
    dataIndex: "key",
    key: "key",
    render: (text) => {
      try {
        return screenLabels[rowLabels[text]];
      } catch (e) {
        return text;
      }
    },
  },
  {
    dataIndex: "value",
    key: "value",
    render: (text, data) => {
      let result = epochToDate(text, "DD-MMM-YYYY");
      if (data.key !== "date" || result === "Invalid date") {
        return text;
      } else {
        return result;
      }
    },
  },
];

class SubmitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { recordPayment } = this.props;
    let transaction_details = getNestedValue(
      recordPayment,
      "data.transaction_details",
      "array"
    );
    let screenLabels = getNestedValue(
      this.props,
      "screenMetaData.data.screen_labels.oyo-secure-web"
    );
    return (
      <div className="mainContainer">
        <div className="payment">
          <div className="success">
            <div className="success__logo">
              <img src={image} />
            </div>
            <div className="success__text">
              <h3>
                <b>
                  {getNestedValue(
                    screenLabels,
                    "SECURE_WEB_TITLE_OFFLINE_PAYMENT_RECEIVED"
                  ) || "Offline Payment Proofs Received"}
                </b>
              </h3>
              <p>
                {getNestedValue(
                  screenLabels,
                  "SECURE_WEB_TITLE_OFFLINE_PAYMENT_DESCRIPTION"
                ) ||
                  "Our representative will review your documents in the next 24 hrs. Your wallet balance will be updated post approval."}
              </p>
            </div>
            <div className="success__details">
              <Table
                columns={columns(screenLabels)}
                dataSource={transaction_details}
                pagination={false}
                showHeader={false}
                bordered={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ offlinePaymentReducer, paymentReducer }) => {
  return {
    ...offlinePaymentReducer,
    screenMetaData: paymentReducer.screenMetaData,
  };
};

export default connect(mapStateToProps, null)(SubmitPage);
