import React from 'react';
import './style.css';
import Spinner from '../../components/Spinner';
import RequestConfirmation from './components/RequestConfirmation/index';
import PitchDataSection from './components/PitchDataSection/index';
import UploaderSection from "./components/UploaderSection/index";
import TryAgainLater from '../../components/TryAgainLater';
import ErrorToast from '../../components/ErrorToast';

export default class InstantAdvancesRender extends React.PureComponent {
    render() {
        // eslint-disable-next-line
        const {pitchData, lockLoanData, applyNow, submitDetails, submitDetailsData, errorToast, hotelId} = this.props;
        return (
            <div className="Container">
                {pitchData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner/>
                    </div>
                    :
                    pitchData.data ?
                        !pitchData.data.status ?
                            <PitchDataSection
                                amount={pitchData.data.amount}
                                months={pitchData.data.months}
                                emiAmount={pitchData.data.emi_data && pitchData.data.emi_data.length ? pitchData.data.emi_data[0].amount : null}
                                revenuePercentage={pitchData.data.revenue_percentage}
                                applyNow={applyNow}/>
                            :
                            pitchData.data.status === "REQUESTED" ?
                                <UploaderSection data={pitchData.data} submitDetails={submitDetails} hotelId={hotelId} />
                                :
                                pitchData.data.status === "SUBMITTED" ?
                                    <RequestConfirmation data={pitchData.data} />
                                    :
                                    null
                        :
                        <TryAgainLater />
                }
                {lockLoanData.isFetching || submitDetailsData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner/>
                    </div>
                    : null
                }
                <ErrorToast error={errorToast} />
            </div>
        );
    }
}
