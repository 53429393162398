import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import NotFoundScreen from '../components/NotFound';
import InstantAdvancesScreen from '../modules/InstantAdvances';
import ContractScreen from '../modules/InstantAdvances/components/Terms';
import PLBScreen from '../modules/PLB';
import PaymentScreen from '../modules/Payment';
import PaymentMerchant from '../modules/Payment/Components/PaymentMerchant'
import CallbackRender from '../modules/Payment/Components/CallbackRender'
import WhatsappOptinScreen from '../modules/WhatsappOptin'
import VisibilityBoostScreen from '../modules/VisibilityBoost'
import WebsiteScreen from '../modules/PublicPagesWebsite'
import ReportScreen from '../modules/PublicPagesReport'
import SmartPricingScreen from '../modules/SmartPricing'
import RankingScreen from '../modules/Ranking'
import OfflinePayment from '../modules/OfflinePayment';
import SubmitPage from '../modules/OfflinePayment/submitPage';
import { getNestedValue } from '../utils/objectNesting';
import { CALLBACK_URL, ORDER_URL, BOLETO_URL } from './constant';
import { urlCompare } from '../utils/urlCompare';
import BoletoScreen from '../modules/Boleto';
import gaConstants from '../constants/gaConstants';

class RouterMain extends Component {
	constructor(props) {
		super(props);
		this.currentPathname = null;
	}

	componentDidMount() {
		const pathname = getNestedValue(this.props, 'location.pathname', 'string');

		if (urlCompare(pathname, CALLBACK_URL)) {
			window.history.pushState(null, null, window.document.URL);
		} else if (urlCompare(pathname, ORDER_URL)) {
			this.currentPathname = ORDER_URL;
		}

		this.props.history.listen(this.historyChange);
		window.addEventListener('popstate', this.handleOnUrlChange, true);
	}

	componentWillUnmount() {
		window.removeEventListener('popstate', this.handleOnUrlChange, true);
	}

	handleOnUrlChange = (e) => {
		/* https://support.google.com/chrome/thread/8721521?hl=en */
		const pathname = getNestedValue(this.props, 'location.pathname');
		if (urlCompare(pathname, CALLBACK_URL)) {
			const url = localStorage.getItem('orderUrl');
			if (url) {
				ReactGA.event({
					category: gaConstants.categories.SECURE,
					action: gaConstants.actions.secure.BACK,
					txnid: localStorage.getItem('txnid') || 'empty'
				});

				this.props.history.push(url);
			} else {
				console.log('1 else')
				window.history.pushState(null, null, window.document.URL);
			}
		}
	}

	historyChange = ({ pathname }) => {
		if (urlCompare(this.currentPathname, ORDER_URL) && urlCompare(pathname, CALLBACK_URL)) {
			console.log('2 if')
			this.props.history.go(1);
		}
		this.currentPathname = pathname;
	}

	render() {
		return (
			<div>
				<Switch>
					{/*<Route exact path='/' component={HomeScreen} />*/}
					<Route exact path='/renovation-advance/:hotel_id' component={InstantAdvancesScreen} />
					<Route path="/contract/:hotel_id" component={ContractScreen} />
					<Route path="/plb/:token" component={PLBScreen} />
					<Route path="/boost/:token" component={VisibilityBoostScreen} />
					<Route path="/website/:token" component={WebsiteScreen} />
					<Route path="/advanced_reports/:token" component={ReportScreen} />
					<Route path="/smart_pricing/:token" component={SmartPricingScreen} />
					<Route path="/ranking/:token" component={RankingScreen} />
					<Route path="/whatsapp/optin" component={WhatsappOptinScreen} />
					<Route path="/payment/final" component={PaymentMerchant} />
					<Route path="/payment/offline" component={OfflinePayment} />
					<Route path="/payment/submit" component={SubmitPage} />
					<Route path="/payment/callback" component={CallbackRender} />
					<Route path="/reconciliation/redirect-to-gateway" component={PaymentScreen} />
					<Route path={`${BOLETO_URL}/:token`} component={BoletoScreen} />
					<Route path="*" component={NotFoundScreen} />
				</Switch>
			</div>
		)
	}
}

export default withRouter(RouterMain);
