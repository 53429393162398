import * as actionTypes from './actionTypes';
import { InstantAdvancesState as initialState } from './state';
import _ from 'lodash';
// import { PITCH_DATA } from './Stub';

let type, key, uploadDocumentData, uploadDocumentTypeData, uploadDocumentKeyData, urlList, dataWithOnlyUrlList;

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SAVE_PITCH_DATA:
            return {
                ...state,
                pitchData: {
                    data: action.payload,
                    // data: PITCH_DATA,                                 // HACK HERE
                    error: false,
                    isFetching: false
                }
            };

        case actionTypes.START_FETCHING_PITCH_DATA:
            return {
                ...state,
                pitchData: {
                    ...state.pitchData,
                    isFetching: true,
                    error: false
                }
            };

        case actionTypes.ERROR_FETCHING_PITCH_DATA:
            return {
                ...state,
                pitchData: {
                    ...state.pitchData,
                    isFetching: false,
                    error: action.payload
                    // error: false,
                    // data: PITCH_DATA
                }
            };

        case actionTypes.START_LOCKING_LOAN:
            return {
                ...state,
                lockLoanData: {
                    isFetching: true,
                    error: false
                }
            };

        case actionTypes.ERROR_LOCKING_LOAN:
            return {
                ...state,
                lockLoanData: {
                    isFetching: false,
                    error: action.payload                // HACK HERE
                    // error: false,
                }
                // ,pitchData: {                                 // HACK HERE
                //     ...state.pitchData,
                //     data: {
                //         ...state.pitchData.data,
                //         status: "REQUESTED",
                //         pdc: {
                //             "pdc1": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc1_58765_1558969598816.jpg",
                //             "pdc2": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc2_58765_1558969606222.jpg",
                //             "pdc3": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc3_58765_1558969612492.jpg",
                //             "pdc4": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc4_58765_1558969619029.jpg",
                //             "pdc5": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc5_58765_1558969640496.jpg",
                //             "pdc6": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc6_58765_1558969650451.jpg",
                //             "pdc7": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc7_58765_1558969656452.jpg",
                //             "pdc8": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc8_58765_1558969663660.jpg",
                //             "pdc9": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/PDC_pdc9_58765_1558969670382.jpg"
                //         },
                //         aadhar: {
                //             "front": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/AADHAR_back_58765_1558969763652.jpg",
                //             "back": "https://prod-owndel-renovation-advance.s3.ap-southeast-1.amazonaws.com/AADHAR_back_58765_1558969763652.jpg"
                //         },
                //         legalEntity: "Davinder Pal",
                //         sample_cheque_url: "https://s3-ap-southeast-1.amazonaws.com/ownerapp/renovation_advance/sample_cheque.jpg"
                //     }
                // }
            };

        case actionTypes.SAVE_LOCK_LOAN_DATA:
            return {
                ...state,
                lockLoanData: {
                    data: action.payload.data,
                    error: false,
                    isFetching: false,
                    success: true
                }
            };


        case actionTypes.START_UPLOADING_DOCUMENT:
            type = action.params.type;
            key = action.params.key;
            uploadDocumentData = _.cloneDeep(state.uploadDocument);
            uploadDocumentTypeData = _.cloneDeep(uploadDocumentData[type]);
            uploadDocumentTypeData[key] = {
                isFetching: true,
                error: false,
                success: false
            };
            uploadDocumentData[type] = uploadDocumentTypeData;
            return {
                ...state,
                uploadDocument: uploadDocumentData
            };

        case actionTypes.ERROR_UPLOADING_DOCUMENT:
            type = action.params.type;
            key = action.params.key;
            uploadDocumentData = _.cloneDeep(state.uploadDocument);
            uploadDocumentTypeData = _.cloneDeep(uploadDocumentData[type]);
            uploadDocumentKeyData = _.cloneDeep(uploadDocumentTypeData[key]);
            uploadDocumentKeyData = {
                ...uploadDocumentKeyData,
                isFetching: false,
                error: action.payload,
                success: false
            };
            uploadDocumentTypeData[key] = uploadDocumentKeyData;
            uploadDocumentData[type] = uploadDocumentTypeData;
            return {
                ...state,
                uploadDocument: uploadDocumentData
            };

        case actionTypes.SAVE_UPLOADED_DOCUMENT:
            type = action.params.type;
            key = action.params.key;
            uploadDocumentData = _.cloneDeep(state.uploadDocument);
            uploadDocumentTypeData = _.cloneDeep(uploadDocumentData[type]);
            uploadDocumentKeyData = _.cloneDeep(uploadDocumentTypeData[key]);
            uploadDocumentKeyData = {
                ...uploadDocumentKeyData,
                data: action.payload,
                error: false,
                isFetching: false,
                success: true
            };
            uploadDocumentTypeData[key] = uploadDocumentKeyData;
            uploadDocumentData[type] = uploadDocumentTypeData;

            urlList = _.cloneDeep(state.pitchData.data[type.toLowerCase()]);
            urlList[key] = action.payload.url;
            dataWithOnlyUrlList = {};
            dataWithOnlyUrlList[type.toLowerCase()] = urlList;
            return {
                ...state,
                uploadDocument: uploadDocumentData,
                pitchData: {
                    ...state.pitchData,
                    data: {
                        ...state.pitchData.data,
                        ...dataWithOnlyUrlList
                    }
                }
            };

        case actionTypes.SAVE_SUBMITTED_DETAILS:
            return {
                ...state,
                submitDetailsData: {
                    success: action.payload.success,
                    error: false,
                    isFetching: false
                }
            };

        case actionTypes.START_SUBMITTING_DETAILS:
            return {
                ...state,
                submitDetailsData: {
                    ...state.submitDetailsData,
                    success: false,
                    isFetching: true,
                    error: false
                }
            };

        case actionTypes.ERROR_SUBMITTING_DETAILS:
            return {
                ...state,
                submitDetailsData: {
                    ...state.submitDetailsData,
                    success: false,
                    isFetching: false,
                    error: action.payload
                }
            };

        case actionTypes.REMOVE_DOCUMENT:
            type = action.params.type;
            key = action.params.key;

            urlList = _.cloneDeep(state.pitchData.data[type.toLowerCase()]);
            urlList[key] = "";
            dataWithOnlyUrlList = {};
            dataWithOnlyUrlList[type.toLowerCase()] = urlList;
            return {
                ...state,
                pitchData: {
                    ...state.pitchData,
                    data: {
                        ...state.pitchData.data,
                        ...dataWithOnlyUrlList
                    }
                }
            };

        case actionTypes.SAVE_FETCHED_CONTRACT:
            return {
                ...state,
                contractData: {
                    data: action.payload,
                    error: false,
                    isFetching: false
                }
            };

        case actionTypes.START_FETCHING_CONTRACT:
            return {
                ...state,
                contractData: {
                    ...state.contractData,
                    data: "",
                    isFetching: true,
                    error: false
                }
            };

        case actionTypes.ERROR_FETCHING_CONTRACT:
            return {
                ...state,
                contractData: {
                    ...state.contractData,
                    isFetching: false,
                    error: action.payload
                }
            };

        default: return state;

    }
};

export default reducer;