import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import RankingRender from './render';
import { bindActionCreators } from "redux";
import * as Actions from './actions';
import parseQueryParams from '../../utils/QueryParamsParser';
import ReactGA from 'react-ga';
import gaConstants from '../../constants/gaConstants';

class RankingScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: null
    };
    this.subscribeRanking = this.subscribeRanking.bind(this)
    this.getJsonFromUrl = this.getJsonFromUrl.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.rankingData.data).length == 0) {
      const { match } = this.props;
      let params = {
        token: decodeURIComponent(match.params.token)
      };
      this.props.actions.fetchRanking(params);
      ReactGA.pageview(gaConstants.pages.RANKING);
      this.getJsonFromUrl();
    }
  }

  getJsonFromUrl() {
    const result = parseQueryParams(this.props.location.search);
    this.setState({ queryParams: result });
  }

  subscribeRanking() {
    const { match } = this.props;
    const { queryParams } = this.state;
    let params = {
      token: decodeURIComponent(match.params.token),
      source: (queryParams && queryParams.source && queryParams.source != "undefined") ? queryParams.source : "OWNER_PCS_CRM"
    };
    this.props.actions.subscribeRanking(params)
    ReactGA.event({
      category: gaConstants.categories.RANKING,
      action: gaConstants.actions.ranking.SUBSCRIBED
    });
  }

  render() {
    const { rankingData, rankingSubscriptionData } = this.props;
    return (
      <RankingRender
        rankingData={rankingData}
        rankingSubscriptionData={rankingSubscriptionData}
        subscribeRanking={this.subscribeRanking}
        queryParams={this.state.queryParams}
        errorToast={this.state.errorToast}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
};

const mapStateToProps = state => ({
  rankingData: state.rankingReducer.rankingData,
  rankingSubscriptionData: state.rankingReducer.rankingSubscription,
});

export default connect(mapStateToProps, mapDispatchToProps)(RankingScreen);