export const VisibilityBoostState = {
    visibilityBoostData: {
      data: {},
      isFetching: false,
      error: false
    },
    visibilityBoostSubscription: {
      data: {},
      isFetching: false,
      error: false,
    }
  }