import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi'

export function saveVisibilityBoost(payload) {
    return {
        type: actionTypes.SAVE_VISIBILITY_BOOST,
        payload: payload
    }
}

export function startFetchingVisibilityBoost() {
    return {
        type: actionTypes.START_FETCHING_VISIBILITY_BOOST,
    }
}

export function errorFetchingVisibilityBoost(error) {
    return {
        type: actionTypes.ERROR_FETCHING_VISIBILITY_BOOST,
        payload: error
    }
}

export function fetchVisibilityBoost(params) {
    const headers = {
        access_token: "access_token"
    }
    return (dispatch) => {
        dispatch(startFetchingVisibilityBoost());
        RESTApi.GET('/owner_ms/api/v1/vas/sales/web/boost/get_pitch', params, (data) => {
            if (data)
                dispatch(saveVisibilityBoost(data))
        }, (error) => {
            dispatch(errorFetchingVisibilityBoost(error))
        }, headers)
    }
}

export function saveSubscribedVisibilityBoost(payload) {
    return {
        type: actionTypes.SAVE_SUBSCRIBED_VISIBILITY_BOOST,
        payload: payload
    }
}

export function startSubscribingVisibilityBoost() {
    return {
        type: actionTypes.START_SUBSCRIBING_VISIBILITY_BOOST,
    }
}

export function errorSubscribingVisibilityBoost(error) {
    return {
        type: actionTypes.ERROR_SUBSCRIBING_VISIBILITY_BOOST,
        payload: error
    }
}

export function subscribeVisibilityBoost(params, data) {
    const headers = {
        access_token: "access_token"
    }
    return (dispatch) => {
        dispatch(startSubscribingVisibilityBoost())
        RESTApi.POST('/owner_ms/api/v1/vas/sales/web/boost/create', params, data, (data) => {
            if (data)
                dispatch(saveSubscribedVisibilityBoost(data))
        }, (error) => {
            dispatch(errorSubscribingVisibilityBoost(error))
        }, headers)
    }
}
