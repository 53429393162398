import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

const ErrorBoxComponent = ({ error }) => {
	if (error === undefined || error === '' || error === {} || error === false) {
		return null;
	}
	return (
		<Alert
			message={error}
			style={{marginBottom: '20px'}}
			type="error"
			showIcon
		/>
	);
};

ErrorBoxComponent.propTypes = {
	error: PropTypes.string
};

export default ErrorBoxComponent;
