import React, { useContext } from 'react';
import Form from '@rjsf/material-ui';
import { Alert, Button, Card } from 'antd';
import { BoletoContext } from '../../context';
import { getNestedValue } from '../../../../utils/objectNesting';
// import { schema, uiSchema } from './schema';
import { widgets } from './Widgets';

const log = (type) => console.log.bind(console, type);

function transformErrors(errors, metaData) {
  return errors.map(error => {
    if (error.name === 'required') {
      error.message = metaData['SECURE_REQUIRED_FIELD'] || "is a required field"
    }
    return error;
  }).filter(error => {
    if (error['message'] == 'should be string') {
      return false;
    }
    return true;
  });
}

const BoletoForm = () => {
  const { boletoData, metaData, onInitiatePayment, saveUserFormDetails } = useContext(BoletoContext);
  const userLoading = getNestedValue(boletoData, 'userData.isFetching');
  const schema = getNestedValue(boletoData, 'userData.data.data.schema.form_schema', 'object');
  const uiSchema = getNestedValue(boletoData, 'userData.data.data.schema.ui_schema', 'object');
  const userFormDetails = getNestedValue(boletoData, 'userFormDetails', 'object');
  const formLoading = getNestedValue(boletoData, 'formData.isFetching');
  const formError = getNestedValue(boletoData, 'formData.error');

  const formSubmit = ({ formData }) => {
    onInitiatePayment(formData)
  }

  return (
    <div className="form_schema">
      <Card
        loading={userLoading}
        className="form_schema_card"
      >
        <Form
          schema={schema}
          noHtml5Validate
          onChange={({ formData }) => saveUserFormDetails(formData)}
          onSubmit={formSubmit}
          onError={log("errors")}
          showErrorList={false}
          transformErrors={(errors) => transformErrors(errors, metaData)}
          formData={userFormDetails}
          uiSchema={uiSchema}
          widgets={widgets}
        // liveValidate
        >
          {formError && (
            <Alert
              message={formError}
              style={{ marginTop: 12 }}
              type="error"
              showIcon
            />
          )}
          <Button
            type="primary"
            loading={formLoading}
            disabled={formLoading}
            size="large"
            className="form__button"
            htmlType="submit"
          >
            {metaData['SECURE_BOLETO_FORM_BTN'] || 'Confirm & Proceed'}
          </Button>
        </Form>
      </Card>
    </div>
  );
}

export default BoletoForm;
