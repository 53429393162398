import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Alert, Button } from 'antd';
import ReactGA from 'react-ga';
import '../style.scss';
import { connect } from 'react-redux';
import * as Actions from '../actions';
import { bindActionCreators } from "redux";
import * as images from '../../../assets/images/oyo-logo-white.png'
import Spinner from '../../../components/Spinner';
import ErrorToast from '../../../components/ErrorToast';
import moment from 'moment';
import { fetchScreenMetadata } from '../../Payment/actions';
import { getNestedValue } from '../../../utils/objectNesting';
import { PAY_LABELS } from '../constant';
import gaConstants from '../../../constants/gaConstants';
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol';

class CallbackRender extends React.PureComponent {
    constructor(props) {
        super(props);
        this.txnid = localStorage.getItem('txnid');
    }

    componentDidMount() {
        const metaData = getNestedValue(this.props, 'screenMetaData.data', 'object');
        if (_.isEmpty(metaData)) {
            this.props.actions.fetchScreenMetadata({ features: 'oyo-secure-web' });
        }

        let body = {
            txnid: this.txnid
        }

        try {
            body = JSON.parse(decodeURIComponent(this.props.location.search.substring(8)))
        } catch (e) { }

        this.props.actions.fetchPaymentCallback(body);
    }

    paymentPage = () => {
        const orderUrl = localStorage.getItem('orderUrl');
        ReactGA.event({
            category: gaConstants.categories.SECURE,
            action: gaConstants.actions.xendit.RETRY,
            txnid: this.txnid
        });
        this.props.history.push(orderUrl);
    }

    render() {
        const { paymentCallback, isFetching, error, screenMetaData } = this.props;
        const screen_labels = getNestedValue(screenMetaData, 'data.screen_labels.oyo-secure-web', 'object');
        const orderUrl = localStorage.getItem('orderUrl');

        const warnMsg = screen_labels['SECURE_WEB_TITLE_RETRY_BUTTON_CLICK'] || PAY_LABELS['warnMsg'];
        const warnDis = screen_labels['SECURE_WEB_TITLE_NO_BACK_REFRESH'] || PAY_LABELS['warnDis'];

        const data = paymentCallback['data'] || {};
        const { amount, currency_code, hotel, payment_time, status } = data;
        const statusStr = (status || '').toUpperCase();

        return (
            <div className="mainContainer">
                {isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    :
                    error ?
                        <ErrorToast error={error} />
                        :
                        <div className="receipt">
                            <div className="receipt__header">
                                <img src={images} className="receipt__header--logo" />
                            </div>
                            {(statusStr === 'SUCCESS' || statusStr === 'PENDING') ?
                                <div>
                                    <div className="receipt__heading">
                                        {screen_labels['SECURE_WEB_PAYMENT_RECEIPT'] || 'PAYMENT RECEIPT'}
                                    </div>
                                    <div className="receipt__paymentAmount">
                                        {screen_labels['SECURE_WEB_THANK_YOU'] || 'Thank you'},
                                        <br />
                                        {screen_labels['SECURE_WEB_RECEIVED_PAYMENT_TEXT'] || 'We have received payment of'}
                                        <br />
                                        <span className="receipt__paymentAmount--amt">
                                            {getCurrencySymbol(currency_code) + ' '}{amount}
                                        </span>
                                        <br />
                                        {screen_labels['SECURE_WEB_BALANCE_UPDATE_TEXT'] || 'Your current balance will get updated in 2 days'}
                                    </div>
                                    <div className="receipt__details">
                                        <div className="receipt__divider"></div>
                                        <div className="receipt__detail">
                                            <div className="left">
                                                {screen_labels['SECURE_WEB_LABEL_TRANSACTION_ID'] || 'Transaction ID'}
                                            </div>
                                            <div className="right">
                                                {this.txnid}
                                            </div>
                                        </div>
                                        <div className="receipt__divider"></div>
                                        {payment_time ? <div className="receipt__detail">
                                            <div className="left">
                                                {screen_labels['SECURE_WEB_LABEL_DATE'] || 'Date'}
                                            </div>
                                            <div className="right">
                                                {moment(payment_time).format('DD-MMM-YYYY HH:MM:SS')}
                                            </div>
                                        </div> : null}
                                        {payment_time && <div className="receipt__divider"></div>}
                                        {hotel ? <div className="receipt__detail">
                                            <div className="left">
                                                {screen_labels['SECURE_WEB_HOTEL'] || 'Hotel'}
                                            </div>
                                            <div className="right">
                                                {hotel}
                                            </div>
                                        </div> : null}
                                    </div>
                                </div>
                                :
                                (
                                    <div>
                                        <div className="receipt__error" style={{ paddingBottom: '20px' }}>
                                            {screen_labels['SECURE_WEB_ERROR_TEXT'] || PAY_LABELS['errMsg']}
                                        </div>
                                        <div className="receipt__warning">
                                            <Alert
                                                message={warnMsg}
                                                description={warnDis}
                                                type="warning"
                                                showIcon
                                            />
                                            {orderUrl && (
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    className="receipt__warning__btn"
                                                    onClick={this.paymentPage}
                                                >
                                                    {screen_labels['SECURE_WEB_TITLE_RETRY_PAYMENT'] || PAY_LABELS['warnBtn']}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                            <div className="receipt__divider"></div>
                            <div className="receipt__footer">
                                <strong>Oravel Stays Private Limited</strong><br />
                                <strong>Reg. office: </strong> Delhi Rectangle Regus,
                                Level 4,Rectangle 1,Commercial Complex D-4,Saket ,
                                New Delhi -110017
                        </div>
                        </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({
            ...Actions,
            fetchScreenMetadata
        },
            dispatch)
    }
};

const mapStateToProps = state => ({
    paymentCallback: state.paymentReducer.paymentCallback.data,
    isFetching: state.paymentReducer.paymentCallback.isFetching,
    error: state.paymentReducer.paymentCallback.error,
    screenMetaData: state.paymentReducer.screenMetaData
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CallbackRender)
);
