const pages = {
    INSTANT_ADVANCE: "/renovation-advance",
    CONTRACTS: "/contract",
    PLB: "/plb",
    RANKING: "/ranking",
    SMART_PRICING: "/smart_pricing",
    BOOST: "/boost",
    BUSINESS_REPORTS: "/report",
    HOTEL_WEBSITE: "/website",
    SECURE: "/secure"
}

const categories = {
    INSTANT_ADVANCE: "Instant Advance",
    PLB: "PLB",
    RANKING: "RANKING",
    SMART_PRICING: "SMART PRICING",
    BOOST: "BOOST",
    BUSINESS_REPORTS: "BUSINESS REPORTS",
    HOTEL_WEBSITE: "HOTEL WEBSITE",
    SECURE: "Secure",
    BOLETO: "Boleto",
    XENDIT: "Xendit"
};

const actions = {
    instant_advance: {
        LOCKED_LOAN: "Locked Loan",
        UPLOAD_DOCUMENT: "Upload Document",
        REMOVE_DOCUMENT: "Remove Document",
        SUBMIT_DETAILS: "Submit Details"
    },
    plb: {
        SUBSCRIBED: "subscribed"
    },
    ranking: {
        SUBSCRIBED: "subscribed"
    },
    smart_pricing: {
        SUBSCRIBED: "subscribed"
    },
    boost: {
        SUBSCRIBED: "subscribed"
    },
    business_reports: {
        SUBSCRIBED: "subscribed"
    },
    hotel_website: {
        SUBSCRIBED: "subscribed"
    },
    secure: {
        PAY_NOW: "co-oyo-web-online-payment-pay-now",
        RECORD_OFFLINE_PAYMENT: "co-oyo-web-offline-payment-page-redirection",
        PAYMENT_MODE: "co-oyo-web-offline-payment-submit-payment-mode",
        SUBMIT_PROOFS: "co-oyo-web-offline-payment-submit",
        VERIFY_API_FAILED: "co-oyo-web-verify-payment-api-failed",
        VERIFY_API_NOT_SUCCESS: "co-oyo-web-verify-payment-api-not-success",
        VERIFY_API_SUCCESS: "co-oyo-web-verify-payment-success",
        RETRY: "co-oyo-web-retry-payment",
        BACK: "co-oyo-web-browser-back"
    },
    boleto: {
        BOLETO_PAYMENT: 'boleto-page-redirection',
        BOLETO_FORM_SUBMIT: 'boleto-form-submit',
        BOLETO_PDF_DOWNLOAD: 'boleto-pdf-download'
    },
    xendit: {
        XENDIT_PAYMENT: 'xendit-pay-now'
    }
}

export default {
    pages,
    categories,
    actions
};