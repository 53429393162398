export const PaymentState = {
  paymentData: {
    data: {},
    isFetching: false,
    error: false
  },
  paymentCallback: {
    data: {},
    isFetching: false,
    error: false
  },
  screenMetaData: {
    data: {},
    isFetching: false,
    error: false
  },
  gatewayData: {
    data: {},
    isFetching: false,
    error: false
  },
  hotelInfoData: {
    data: {}
  }
};
