import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import SmartPricingRender from './render';
import { bindActionCreators } from "redux";
import * as Actions from './actions';
import parseQueryParams from '../../utils/QueryParamsParser';
import ReactGA from 'react-ga';
import gaConstants from '../../constants/gaConstants';

class SmartPricingScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: null
    };
    this.subscribeSmartPricing = this.subscribeSmartPricing.bind(this)
    this.getJsonFromUrl = this.getJsonFromUrl.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.smartPricingData.data).length == 0) {
      const { match } = this.props;
      let params = {
        token: decodeURIComponent(match.params.token)
      };
      this.props.actions.fetchSmartPricing(params);
      ReactGA.pageview(gaConstants.pages.SMART_PRICING);
      this.getJsonFromUrl();
    }
  }

  getJsonFromUrl() {
    const result = parseQueryParams(this.props.location.search);
    this.setState({ queryParams: result });
  }

  subscribeSmartPricing() {
    const { match } = this.props;
    const { queryParams } = this.state;
    let params = {
      token: decodeURIComponent(match.params.token),
      source: (queryParams && queryParams.source) ? queryParams.source : "OWNER_PCS_CRM"
    };
    this.props.actions.subscribeSmartPricing(params);
    ReactGA.event({
      category: gaConstants.categories.SMART_PRICING,
      action: gaConstants.actions.smart_pricing.SUBSCRIBED
    });
  }

  render() {
    const { smartPricingData, smartPricingSubscriptionData } = this.props;
    return (
      <SmartPricingRender
        smartPricingData={smartPricingData}
        smartPricingSubscriptionData={smartPricingSubscriptionData}
        subscribeSmartPricing={this.subscribeSmartPricing}
        errorToast={this.state.errorToast}
        queryParams={this.state.queryParams}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
};

const mapStateToProps = state => ({
  smartPricingData: state.smartPricingReducer.smartPricingData,
  smartPricingSubscriptionData: state.smartPricingReducer.smartPricingSubscription,
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartPricingScreen);