import React, { useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { getNestedValue } from '../../../../../utils/objectNesting';

const CustomDatePicker = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const title = (getNestedValue(props, 'schema.title') || 'Date') + (props['required'] && ' *');
  const minDate = getDateValue(getNestedValue(props, 'uiSchema.minValidation'), 'min');
  const maxDate = getDateValue(getNestedValue(props, 'uiSchema.maxValidation'), 'max');

  const handleDateChange = (date) => {
    const epochDate = moment(date).valueOf();
    setSelectedDate(epochDate);
    props.onChange(epochDate)
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        autoOk
        label={title}
        minDate={minDate}
        maxDate={maxDate}
        format="Do MMM, YYYY"
        value={selectedDate}
        onChange={handleDateChange}
        animateYearScrolling
      />
    </MuiPickersUtilsProvider>
  );
}

const getDateValue = (validation, dateType) => {
  const todayDate = new Date().getTime();
  const maxDate = moment('2030-12-31').valueOf();
  let dateVal = dateType === 'min' ? 0 : maxDate;

  if (validation) {
    const { type, value } = validation;
    if (type == 'date') {
      if (value == 'today') {
        return todayDate;
      }
    } else if (type == 'range') {
      return moment(todayDate).add(value, 'days');
    }
  }

  return dateVal;
}

export default CustomDatePicker;
