import React, { useContext } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { BoletoContext } from '../../context';

const Header = () => {
  const { metaData, onGoBack } = useContext(BoletoContext);
  return (
    <div className="card_container header_container">
      <div className="header__icon">
        <ArrowBackIcon onClick={onGoBack} />
      </div>
      <div className="header__text">
        {metaData['SECURE_BOLETO_GENERATE'] || 'Generate Boleto Voucher'}
      </div>
    </div>
  );
}

export default Header;
