import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from '../../actions';
import Spinner from '../../../../components/Spinner';
import ReactGA from 'react-ga';
import gaConstants from '../../../../constants/gaConstants';
import TryAgainLater from '../../../../components/TryAgainLater';

class ContractScreen extends Component{
	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		html: ""
	// 	}
	// }
    componentDidMount(){
    	const { match } = this.props;
    	const params = {
			hotel_id: match.params.hotel_id
		};
		this.props.actions.fetchContract(params);
		ReactGA.pageview(gaConstants.pages.CONTRACTS);
    }
	// componentDidUpdate(prevProps, prevState, snapshot) {
    // 	const { contractData } = this.props;
    // 	if(contractData !== prevProps.contractData && contractData.data)
    // 		this.setState({ html: contractData.data });
	// }

	render(){
    	const { contractData } = this.props;
		return(
			contractData.isFetching ?
				<div style={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Spinner />
				</div>
				:
				contractData.data ?
					<>
						<p dangerouslySetInnerHTML={{__html : contractData.data}} />
					</>
					:
					<TryAgainLater />
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(Actions, dispatch)
	}
};
const mapStateToProps = (state, props) => {
	return {
		contractData: state.instantAdvancesReducer.contractData
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractScreen);