const currencies = {
	"INR": {
		"symbol": "₹",
		"id": 1,
		"currency_code": "INR"
	},
	"MYR": {
		"symbol": "RM",
		"id": 2,
		"currency_code": "MYR"
	},
	"CNY": {
		"symbol": "¥",
		"id": 3,
		"currency_code": "CNY"
	},
	"NPR": {
		"symbol": "NPR",
		"id": 4,
		"currency_code": "NPR"
	},
	"IDR": {
		"symbol": "Rp",
		"id": 5,
		"currency_code": "IDR"
	},
	"AED": {
		"symbol": "AED",
		"id": 6,
		"currency_code": "AED"
	},
	"GBP": {
		"symbol": "£",
		"id": 14,
		"currency_code": "GBP"
	},
	"SAR": {
		"symbol": "SAR",
		"id": 15,
		"currency_code": "SAR"
	},
	"JPY": {
		"symbol": "¥",
		"id": 16,
		"currency_code": "JPY"
	},
	"PHP": {
		"symbol": "₱",
		"id": 17,
		"currency_code": "PHP"
	},
	"VND": {
		"symbol": "₫",
		"id": 18,
		"currency_code": "VND"
	},
	"OMR": {
		"symbol": "﷼",
		"id": 19,
		"currency_code": "OMR"
	},
	"LKR": {
		"symbol": "LKR",
		"id": 20,
		"currency_code": "LKR"
	},
	"SGD": {
		"symbol": "S$",
		"id": 21,
		"currency_code": "SGD"
	},
	"EUR": {
		"symbol": "€",
		"id": 22,
		"currency_code": "EUR"
	},
	"THB": {
		"symbol": "฿",
		"id": 23,
		"currency_code": "THB"
	},
	"BRL": {
		"symbol": "R$",
		"id": 24,
		"currency_code": "BRL"
	},
	"BHD": {
		"symbol": "BD",
		"id": 25,
		"currency_code": "BHD"
	},
	"USD": {
		"symbol": "$",
		"id": 29,
		"currency_code": "USD"
	},
	"AUD": {
		"symbol": "A$",
		"id": 46,
		"currency_code": "AUD"
	},
	"AFN": {
		"symbol": "؋",
		"id": 36,
		"currency_code": "AFN"
	},
	"ALL": {
		"symbol": "L",
		"id": 38,
		"currency_code": "ALL"
	},
	"DZD": {
		"symbol": "د.ج",
		"id": 39,
		"currency_code": "DZD"
	},
	"AOA": {
		"symbol": "Kz",
		"id": 40,
		"currency_code": "AOA"
	},
	"XCD": {
		"symbol": "$",
		"id": 41,
		"currency_code": "XCD"
	},
	"ARS": {
		"symbol": "$",
		"id": 42,
		"currency_code": "ARS"
	},
	"AMD": {
		"symbol": "֏",
		"id": 43,
		"currency_code": "AMD"
	},
	"AWG": {
		"symbol": "ƒ",
		"id": 44,
		"currency_code": "AWG"
	},
	"AZN": {
		"symbol": "₼",
		"id": 45,
		"currency_code": "AZN"
	},
	"BDT": {
		"symbol": "৳",
		"id": 54,
		"currency_code": "BDT"
	},
	"BBD": {
		"symbol": "$",
		"id": 55,
		"currency_code": "BBD"
	},
	"BYR": {
		"symbol": "Br",
		"id": 56,
		"currency_code": "BYR"
	},
	"BZD": {
		"symbol": "$",
		"id": 57,
		"currency_code": "BZD"
	},
	"XOF": {
		"symbol": "Fr",
		"id": 58,
		"currency_code": "XOF"
	},
	"BMD": {
		"symbol": "$",
		"id": 59,
		"currency_code": "BMD"
	},
	"BTN": {
		"symbol": "Nu.",
		"id": 60,
		"currency_code": "BTN"
	},
	"BOB": {
		"symbol": "Bs.",
		"id": 61,
		"currency_code": "BOB"
	},
	"BAM": {
		"symbol": "KM",
		"id": 62,
		"currency_code": "BAM"
	},
	"BWP": {
		"symbol": "P",
		"id": 63,
		"currency_code": "BWP"
	},
	"BND": {
		"symbol": "$",
		"id": 64,
		"currency_code": "BND"
	},
	"BGN": {
		"symbol": "лв",
		"id": 65,
		"currency_code": "BGN"
	},
	"BIF": {
		"symbol": "Fr",
		"id": 66,
		"currency_code": "BIF"
	},
	"KHR": {
		"symbol": "៛",
		"id": 67,
		"currency_code": "KHR"
	},
	"XAF": {
		"symbol": "Fr",
		"id": 68,
		"currency_code": "XAF"
	},
	"CAD": {
		"symbol": "$",
		"id": 69,
		"currency_code": "CAD"
	},
	"CVE": {
		"symbol": "Esc",
		"id": 70,
		"currency_code": "CVE"
	},
	"KYD": {
		"symbol": "$",
		"id": 71,
		"currency_code": "KYD"
	},
	"CLP": {
		"symbol": "$",
		"id": 72,
		"currency_code": "CLP"
	},
	"COP": {
		"symbol": "$",
		"id": 73,
		"currency_code": "COP"
	},
	"KMF": {
		"symbol": "Fr",
		"id": 74,
		"currency_code": "KMF"
	},
	"CRC": {
		"symbol": "₡",
		"id": 75,
		"currency_code": "CRC"
	},
	"HRK": {
		"symbol": "kn",
		"id": 76,
		"currency_code": "HRK"
	},
	"CUC": {
		"symbol": "$",
		"id": 77,
		"currency_code": "CUC"
	},
	"CZK": {
		"symbol": "Kč",
		"id": 78,
		"currency_code": "CZK"
	},
	"DKK": {
		"symbol": "kr",
		"id": 79,
		"currency_code": "DKK"
	},
	"DJF": {
		"symbol": "Fr",
		"id": 80,
		"currency_code": "DJF"
	},
	"DOP": {
		"symbol": "$",
		"id": 81,
		"currency_code": "DOP"
	},
	"EGP": {
		"symbol": "£",
		"id": 82,
		"currency_code": "EGP"
	},
	"ERN": {
		"symbol": "Nfk",
		"id": 83,
		"currency_code": "ERN"
	},
	"ETB": {
		"symbol": "Br",
		"id": 84,
		"currency_code": "ETB"
	},
	"FKP": {
		"symbol": "£",
		"id": 85,
		"currency_code": "FKP"
	},
	"FJD": {
		"symbol": "$",
		"id": 86,
		"currency_code": "FJD"
	},
	"XPF": {
		"symbol": "Fr",
		"id": 87,
		"currency_code": "XPF"
	},
	"GEL": {
		"symbol": "ლ",
		"id": 88,
		"currency_code": "GEL"
	},
	"GHS": {
		"symbol": "₵",
		"id": 89,
		"currency_code": "GHS"
	},
	"GIP": {
		"symbol": "£",
		"id": 90,
		"currency_code": "GIP"
	},
	"GTQ": {
		"symbol": "Q",
		"id": 91,
		"currency_code": "GTQ"
	},
	"GNF": {
		"symbol": "Fr",
		"id": 92,
		"currency_code": "GNF"
	},
	"GYD": {
		"symbol": "$",
		"id": 93,
		"currency_code": "GYD"
	},
	"HTG": {
		"symbol": "G",
		"id": 94,
		"currency_code": "HTG"
	},
	"HNL": {
		"symbol": "L",
		"id": 95,
		"currency_code": "HNL"
	},
	"HKD": {
		"symbol": "$",
		"id": 96,
		"currency_code": "HKD"
	},
	"HUF": {
		"symbol": "Ft",
		"id": 97,
		"currency_code": "HUF"
	},
	"ISK": {
		"symbol": "kr",
		"id": 98,
		"currency_code": "ISK"
	},
	"IRR": {
		"symbol": "﷼",
		"id": 99,
		"currency_code": "IRR"
	},
	"IQD": {
		"symbol": "ع.د",
		"id": 100,
		"currency_code": "IQD"
	},
	"ILS": {
		"symbol": "₪",
		"id": 101,
		"currency_code": "ILS"
	},
	"JMD": {
		"symbol": "$",
		"id": 102,
		"currency_code": "JMD"
	},
	"JOD": {
		"symbol": "د.ا",
		"id": 103,
		"currency_code": "JOD"
	},
	"KZT": {
		"symbol": "лв",
		"id": 104,
		"currency_code": "KZT"
	},
	"KES": {
		"symbol": "Sh",
		"id": 105,
		"currency_code": "KES"
	},
	"KWD": {
		"symbol": "د.ك",
		"id": 106,
		"currency_code": "KWD"
	},
	"KGS": {
		"symbol": "лв",
		"id": 107,
		"currency_code": "KGS"
	},
	"LBP": {
		"symbol": "ل.ل",
		"id": 108,
		"currency_code": "LBP"
	},
	"LSL": {
		"symbol": "L",
		"id": 109,
		"currency_code": "LSL"
	},
	"LRD": {
		"symbol": "$",
		"id": 110,
		"currency_code": "LRD"
	},
	"MXN": {
		"symbol": "MXN",
		"id": 26,
		"currency_code": "MXN"
	},
	"LYD": {
		"symbol": "ل.د",
		"id": 111,
		"currency_code": "LYD"
	},
	"CHF": {
		"symbol": "Fr",
		"id": 112,
		"currency_code": "CHF"
	},
	"MOP": {
		"symbol": "P",
		"id": 113,
		"currency_code": "MOP"
	},
	"MGA": {
		"symbol": "Ar",
		"id": 114,
		"currency_code": "MGA"
	},
	"MWK": {
		"symbol": "MK",
		"id": 115,
		"currency_code": "MWK"
	},
	"MVR": {
		"symbol": ".ރ",
		"id": 116,
		"currency_code": "MVR"
	},
	"MRO": {
		"symbol": "UM",
		"id": 117,
		"currency_code": "MRO"
	},
	"MUR": {
		"symbol": "₨",
		"id": 118,
		"currency_code": "MUR"
	},
	"MDL": {
		"symbol": "L",
		"id": 119,
		"currency_code": "MDL"
	},
	"MNT": {
		"symbol": "₮",
		"id": 120,
		"currency_code": "MNT"
	},
	"MAD": {
		"symbol": "د.م.",
		"id": 121,
		"currency_code": "MAD"
	},
	"MZN": {
		"symbol": "MT",
		"id": 122,
		"currency_code": "MZN"
	},
	"MMK": {
		"symbol": "Ks",
		"id": 123,
		"currency_code": "MMK"
	},
	"NAD": {
		"symbol": "$",
		"id": 124,
		"currency_code": "NAD"
	},
	"NZD": {
		"symbol": "$",
		"id": 125,
		"currency_code": "NZD"
	},
	"NIO": {
		"symbol": "C$",
		"id": 126,
		"currency_code": "NIO"
	},
	"NGN": {
		"symbol": "₦",
		"id": 127,
		"currency_code": "NGN"
	},
	"NOK": {
		"symbol": "kr",
		"id": 128,
		"currency_code": "NOK"
	},
	"PKR": {
		"symbol": "₨",
		"id": 129,
		"currency_code": "PKR"
	},
	"PAB": {
		"symbol": "B/.",
		"id": 130,
		"currency_code": "PAB"
	},
	"PGK": {
		"symbol": "K",
		"id": 131,
		"currency_code": "PGK"
	},
	"PYG": {
		"symbol": "₲",
		"id": 132,
		"currency_code": "PYG"
	},
	"PEN": {
		"symbol": "S/.",
		"id": 133,
		"currency_code": "PEN"
	},
	"PLN": {
		"symbol": "zł",
		"id": 134,
		"currency_code": "PLN"
	},
	"QAR": {
		"symbol": "ر.ق",
		"id": 135,
		"currency_code": "QAR"
	},
	"RON": {
		"symbol": "lei",
		"id": 136,
		"currency_code": "RON"
	},
	"RUB": {
		"symbol": "₽",
		"id": 137,
		"currency_code": "RUB"
	},
	"RWF": {
		"symbol": "Fr",
		"id": 138,
		"currency_code": "RWF"
	},
	"WST": {
		"symbol": "T",
		"id": 139,
		"currency_code": "WST"
	},
	"RSD": {
		"symbol": "дин.",
		"id": 140,
		"currency_code": "RSD"
	},
	"SCR": {
		"symbol": "₨",
		"id": 141,
		"currency_code": "SCR"
	},
	"SLL": {
		"symbol": "Le",
		"id": 142,
		"currency_code": "SLL"
	},
	"SOS": {
		"symbol": "Sh",
		"id": 143,
		"currency_code": "SOS"
	},
	"ZAR": {
		"symbol": "R",
		"id": 144,
		"currency_code": "ZAR"
	},
	"SSP": {
		"symbol": "£",
		"id": 145,
		"currency_code": "SSP"
	},
	"SDG": {
		"symbol": "ج.س.",
		"id": 146,
		"currency_code": "SDG"
	},
	"SRD": {
		"symbol": "$",
		"id": 147,
		"currency_code": "SRD"
	},
	"SZL": {
		"symbol": "L",
		"id": 148,
		"currency_code": "SZL"
	},
	"SEK": {
		"symbol": "kr",
		"id": 149,
		"currency_code": "SEK"
	},
	"SYP": {
		"symbol": "ل.س",
		"id": 150,
		"currency_code": "SYP"
	},
	"TWD": {
		"symbol": "$",
		"id": 151,
		"currency_code": "TWD"
	},
	"TJS": {
		"symbol": "ЅМ",
		"id": 152,
		"currency_code": "TJS"
	},
	"TZS": {
		"symbol": "Sh",
		"id": 153,
		"currency_code": "TZS"
	},
	"TOP": {
		"symbol": "T$",
		"id": 154,
		"currency_code": "TOP"
	},
	"TTD": {
		"symbol": "$",
		"id": 155,
		"currency_code": "TTD"
	},
	"TND": {
		"symbol": "د.ت",
		"id": 156,
		"currency_code": "TND"
	},
	"TRY": {
		"symbol": "₺",
		"id": 157,
		"currency_code": "TRY"
	},
	"TMT": {
		"symbol": "m",
		"id": 158,
		"currency_code": "TMT"
	},
	"UGX": {
		"symbol": "Sh",
		"id": 159,
		"currency_code": "UGX"
	},
	"UAH": {
		"symbol": "₴",
		"id": 160,
		"currency_code": "UAH"
	},
	"UYU": {
		"symbol": "$",
		"id": 161,
		"currency_code": "UYU"
	},
	"UZS": {
		"symbol": "лв",
		"id": 162,
		"currency_code": "UZS"
	},
	"VUV": {
		"symbol": "Vt",
		"id": 163,
		"currency_code": "VUV"
	},
	"VEF": {
		"symbol": "Bs",
		"id": 164,
		"currency_code": "VEF"
	},
	"YER": {
		"symbol": "﷼",
		"id": 165,
		"currency_code": "YER"
	},
	"ZMW": {
		"symbol": "ZK",
		"id": 166,
		"currency_code": "ZMW"
	},
	"SHP": {
		"symbol": "£",
		"id": 167,
		"currency_code": "SHP"
	},
	"BSD": {
		"symbol": "$",
		"id": 168,
		"currency_code": "BSD"
	},
	"CDF": {
		"symbol": "Fr",
		"id": 169,
		"currency_code": "CDF"
	},
	"GMD": {
		"symbol": "D",
		"id": 170,
		"currency_code": "GMD"
	},
	"KPW": {
		"symbol": "₩",
		"id": 171,
		"currency_code": "KPW"
	},
	"KRW": {
		"symbol": "₩",
		"id": 172,
		"currency_code": "KRW"
	},
	"LAK": {
		"symbol": "₭",
		"id": 173,
		"currency_code": "LAK"
	},
	"MKD": {
		"symbol": "ден",
		"id": 174,
		"currency_code": "MKD"
	},
	"ANG": {
		"symbol": "ƒ",
		"id": 175,
		"currency_code": "ANG"
	},
	"STN": {
		"symbol": "Db",
		"id": 176,
		"currency_code": "STN"
	}
}


export const getCurrencySymbol = currency_code => {
	return currencies[currency_code] && currencies[currency_code].symbol;
};
