import * as actionTypes from './actionTypes';
import { WhatsappOptinState as initialState } from './state';

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SAVE_WHATSAPP_OPTIN:
            return {
                ...state,
                data: action.payload,
                error: false,
                isFetching: false
            }

        case actionTypes.START_WHATSAPP_OPTIN:
            return {
                ...state,
                isFetching: true,
                error: false
            }

        case actionTypes.ERROR_WHATSAPP_OPTIN:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }

        default: return state

    }
}

export default reducer