import React from 'react';
import {IoIosCheckmark} from 'react-icons/io';
import {IconContext} from "react-icons";

export default class PitchDataSection extends React.PureComponent {
    render() {
        // eslint-disable-next-line
        const {amount, months, emiAmount, revenuePercentage, applyNow} = this.props;
        return (
            <div className="PitchDataContainer">
                <div className="Header">
                    <h2 className="HeaderText">Instant Advances</h2>
                </div>
                <div className="DataContainer">
                    <div className="PitchDataRow" style={{ marginTop: 0 }}>
                        <span className="PitchDataKey">Amount</span>
                        <span className="PitchDataValue" style={{ letterSpacing: '2q' }}>
                            {amount ? ("₹" + amount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")) : ""}
                        </span>
                    </div>
                    <div className="PitchDataRow">
                        <span className="PitchDataKey" style={{ fontWeight: 'normal' }}>Paid duration</span>
                        <span className="PitchDataValue" style={{ fontWeight: 'normal' }}>
                            {months ? months + (months===1?" Month":" Months") : ""}
                        </span>
                    </div>
                    <div className="PitchDataRow">
                        <span className="PitchDataKey" style={{ fontWeight: 'normal' }}>EMI per month</span>
                        <span className="PitchDataValue" style={{ fontWeight: 'normal' }}>
                            {emiAmount}
                        </span>
                    </div>
                    <div className="PitchDataRow">
                        <span className="PitchDataKey" style={{ fontWeight: 'normal' }}>Additional commission</span>
                        <span className="PitchDataValue" style={{ fontWeight: 'normal' }}>
                            {revenuePercentage ? revenuePercentage + "% Month" : ""}
                        </span>
                    </div>
                </div>
                <div className="TextsContainer">
                    <div className="PitchTextsContainerBox">
                        <div className="PitchTextRow" style={{ marginTop: 0 }}>
                            <div className="PitchTextIcon" >
                                <IconContext.Provider value={{ color: "black", size: "1.7em" }}>
                                    <IoIosCheckmark />
                                </IconContext.Provider>
                            </div>
                            <div className="PitchText" >Advance credited directly in your bank account</div>
                        </div>
                        <div className="PitchTextRow" style={{  }}>
                            <div className="PitchTextIcon" >
                                <IconContext.Provider value={{ color: "black", size: "1.7em" }}>
                                    <IoIosCheckmark />
                                </IconContext.Provider>
                            </div>
                            <div className="PitchText" >Within 48 business hours</div>
                        </div>
                        <div className="PitchTextRow" style={{  }}>
                            <div className="PitchTextIcon" >
                                <IconContext.Provider value={{ color: "black", size: "1.7em" }}>
                                    <IoIosCheckmark />
                                </IconContext.Provider>
                            </div>
                            <div className="PitchText" >No Collateral</div>
                        </div>
                        <div className="PitchTextRow" style={{  }}>
                            <div className="PitchTextIcon" >
                                <IconContext.Provider value={{ color: "black", size: "1.7em" }}>
                                    <IoIosCheckmark />
                                </IconContext.Provider>
                            </div>
                            <div className="PitchText" >Only Post Dated Cheques required</div>
                        </div>
                        <div className="PitchTextRow" style={{  }}>
                            <div className="PitchTextIcon" >
                                <IconContext.Provider value={{ color: "black", size: "1.7em" }}>
                                    <IoIosCheckmark />
                                </IconContext.Provider>
                            </div>
                            <div className="PitchText" ><span>100% Transparent</span></div>
                        </div>
                    </div>
                </div>
                <div className="ButtonContainer">
                    <div className="ApplyNowButton" onClick={applyNow}>
                        <div className="ApplyNowText">Apply Now</div>
                    </div>
                </div>
            </div>
        );
    }
}
