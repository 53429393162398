export const ReportState = {
  reportData: {
    data: {},
    isFetching: false,
    error: false
  },
  reportSubscription: {
    data: {},
    isFetching: false,
    error: false,
  }
}