export const START_FETCHING_PITCH_DATA = "START_FETCHING_PITCH_DATA";
export const ERROR_FETCHING_PITCH_DATA = "ERROR_FETCHING_PITCH_DATA";
export const SAVE_PITCH_DATA = "SAVE_PITCH_DATA";
// FOR FETCHING PITCH_DATA

export const START_LOCKING_LOAN = "START_LOCKING_LOAN";
export const ERROR_LOCKING_LOAN = "ERROR_LOCKING_LOAN";
export const SAVE_LOCK_LOAN_DATA = "SAVE_LOCK_LOAN_DATA";
// FOR LOCKING LOAN

export const START_UPLOADING_DOCUMENT = "START_UPLOADING_DOCUMENT";
export const ERROR_UPLOADING_DOCUMENT = "ERROR_UPLOADING_DOCUMENT";
export const SAVE_UPLOADED_DOCUMENT = "SAVE_UPLOADED_DOCUMENT";
// FOR UPLOADING DOCUMENT

export const START_SUBMITTING_DETAILS = "START_SUBMITTING_DETAILS";
export const ERROR_SUBMITTING_DETAILS = "ERROR_SUBMITTING_DETAILS";
export const SAVE_SUBMITTED_DETAILS = "SAVE_SUBMITTED_DETAILS";
// FOR SUBMITTING DETAILS

export const START_FETCHING_CONTRACT = "START_FETCHING_CONTRACT";
export const ERROR_FETCHING_CONTRACT = "ERROR_FETCHING_CONTRACT";
export const SAVE_FETCHED_CONTRACT = "SAVE_FETCHED_CONTRACT";
// FOR FETCHING CONTRACT

export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";