import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import WhatsappOptinRender from './render';
import { bindActionCreators } from "redux";
import * as Actions from './actions';
import parseQueryParams from '../../utils/QueryParamsParser';

class WhatsappOptinScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: null
    };
    this.getJsonFromUrl = this.getJsonFromUrl.bind(this);
  }

  componentDidMount() {
    this.getJsonFromUrl();
    if (this.state.queryParams) {
      let params = {
        phone: this.state.queryParams.token
      };
      if (params.phone) this.props.whatsappOptin(params);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.queryParams != prevState.queryParams && this.state.queryParams && this.state.queryParams.token) {
      let params = {
        phone: this.state.queryParams.token
      };
      this.props.actions.whatsappOptin(params);
    }
    if (prevProps.error != this.props.error && this.props.error) {
      this.setState({
        errorToast: {
          timestamp: new Date(),
          text: "Some Error Occurred. Please try again later."
        }
      })
    }
  }

  getJsonFromUrl() {
    const result = parseQueryParams(this.props.location.search);
    this.setState({ queryParams: result });
  }

  render() {
    const { whatsappOptin, isFetching } = this.props;
    const { errorToast } = this.state;
    return (
      <WhatsappOptinRender
        whatsappOptin={whatsappOptin}
        isFetching={isFetching}
        errorToast={errorToast}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
};

const mapStateToProps = state => ({
  whatsappOptin: state.whatsappOptin.data,
  isFetching: state.whatsappOptin.isFetching,
  error: state.whatsappOptin.error
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappOptinScreen);