import React, { useContext, useState } from 'react';
import image from "../../../../assets/images/total-revenue.svg";
import { BoletoContext } from '../../context';

const Price = () => {
  const [isEdit, setEdit] = useState(false);
  const { amount, currency, onAmountChange, metaData, rechargeType } = useContext(BoletoContext);

  return (
    <div className="card_container price_card">
      <div className="price_container">
        <div className="price__img">
          <img src={image} className="payment--logo" />
        </div>

        <div className="price__amount">
          <div className="price__amount_text">
            <span>
              {metaData['SECURE_WEB_TITLE_PAID_AMOUNT'] || 'Paid Amount'}
            </span>
          </div>

          <div className="price__amount_field">
            <span className="price__amount__currency">
              {currency}
            </span>
            <input
              className={"price__amount__textbox " + (isEdit ? "price__amount__textbox_edit" : "")}
              disabled={!isEdit}
              type="number"
              name="amount"
              min="0"
              onChange={onAmountChange}
              value={amount}
            />
          </div>
        </div>

        {rechargeType != 'INITIAL_RECHARGE' && (
          <div className="price__edit" onClick={() => setEdit(!isEdit)}>
            {metaData['SECURE_WEB_LABEL_EDIT_AMOUNT'] || 'Edit'}
          </div>
        )}
      </div>
    </div>
  );
}

export default Price;
