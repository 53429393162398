import React, { Component } from "react";
import { connect } from 'react-redux';
import parseQueryParams from '../../utils/QueryParamsParser';
import { bindActionCreators } from "redux";
import OfflinePaymentRender from './render';
import * as Actions from './actions';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { fetchScreenMetadata } from "../Payment/actions";

class OfflinePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: null,
    };
    this.getJsonFromUrl = this.getJsonFromUrl.bind(this);
  }

  componentDidMount() {
    const metaData = get(this.props, 'screenMetaData.data', {});
    if (isEmpty(metaData)) {
      this.props.actions.fetchScreenMetadata({ features: 'oyo-secure-web' });
    }

    this.getJsonFromUrl();
  }

  getJsonFromUrl() {
    const result = parseQueryParams(this.props.location.search);
    this.setState({ queryParams: result });
  }

  render() {
    const { queryParams } = this.state;

    return (
      <OfflinePaymentRender
        {...this.props}
        paymentData={(queryParams && queryParams.payment_data) ? queryParams.payment_data : ''}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({
      ...Actions,
      fetchScreenMetadata: fetchScreenMetadata
    },
      dispatch)
  }
};

const mapStateToProps = ({ offlinePaymentReducer, paymentReducer }) => {
  return {
    ...offlinePaymentReducer,
    screenMetaData: paymentReducer.screenMetaData
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflinePayment);
