import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi'

export function savePLB(payload) {
    return {
        type: actionTypes.SAVE_PLB,
        payload: payload
    }
}

export function startFetchingPLB() {
    return {
        type: actionTypes.START_FETCHING_PLB,
    }
}

export function errorFetchingPLB(error) {
    return {
        type: actionTypes.ERROR_FETCHING_PLB,
        payload: error
    }
}

export function fetchPLB(params) {
    const headers = {
        access_token: "access_token"
    }
    return (dispatch) => {
        dispatch(startFetchingPLB());
        RESTApi.GET('/owner_ms/api/v1/vas/sales/web/plb/get_pitch', params, (data) => {
            if (data)
                dispatch(savePLB(data))
        }, (error) => {
            dispatch(errorFetchingPLB(error))
        }, headers)
    }
}

export function saveSubscribedPLB(payload) {
    return {
        type: actionTypes.SAVE_SUBSCRIBED_PLB,
        payload: payload
    }
}

export function startSubscribingPLB() {
    return {
        type: actionTypes.START_SUBSCRIBING_PLB,
    }
}

export function errorSubscribingPLB(error) {
    return {
        type: actionTypes.ERROR_SUBSCRIBING_PLB,
        payload: error
    }
}

export function subscribePLB(params, data) {
    const headers = {
        access_token: "access_token"
    }
    return (dispatch) => {
        dispatch(startSubscribingPLB())
        RESTApi.POST('/owner_ms/api/v1/vas/sales/web/plb/create', params, data, (data) => {
            if (data)
                dispatch(saveSubscribedPLB(data))
        }, (error) => {
            dispatch(errorSubscribingPLB(error))
        }, headers)
    }
}
