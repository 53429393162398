import React, { useContext } from 'react';
import BoletoForm from './BoletoForm';
import { BoletoContext } from '../../context';

const BoletoFormCard = () => {
  const { metaData } = useContext(BoletoContext);

  return (
    <div className="form_card">
      <div className="form_header">
        <h3>
          {metaData['SECURE_BOLETO_FORM_HEADING'] || 'Billing Details'}
        </h3>
        <h4>
          {
            metaData['SECURE_BOLETO_FORM_SUB_HEADING'] ||
            'Please fill in details below to generate the Boleto Voucher for this payment.'
          }
          <br />
          {
            metaData['SECURE_BOLETO_FORM_HINT'] ||
            'Please scroll to the bottom of the page & click on the “Confirm & Proceed” Button to generate the Boleto Voucher.'
          }
        </h4>
      </div>
      <BoletoForm />
    </div>
  );
}

export default BoletoFormCard;
