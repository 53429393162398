import React from 'react';
import './style.css';
import Spinner from '../../components/Spinner';
import TryAgainLater from '../../components/TryAgainLater';
import ErrorToast from '../../components/ErrorToast';
import * as images from '../../assets/images/boost-icon.png';
import * as checkmark from '../../assets/images/checkmark.png';
import * as smart_pricing from '../../assets/images/smart_pricing.png';
import ThanksPage from './Components/ThanksPage';

export default class SmartPricingRender extends React.PureComponent {
    render() {
        const { smartPricingData, subscribeSmartPricing, errorToast, smartPricingSubscriptionData, queryParams } = this.props;
        return (
            <div className="Container">
                {smartPricingData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    :
                    Object.keys(smartPricingData.data).length && queryParams && queryParams.source ?
                        <SmartPricingSubscribeConfirm smartPricingData={smartPricingData} subscribeSmartPricing={subscribeSmartPricing} />
                        :
                        <TryAgainLater />
                }
                {smartPricingSubscriptionData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    : null
                }
                {smartPricingSubscriptionData.data && smartPricingSubscriptionData.data.message ?
                    <div className="SpinnerContainer" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
                        <ThanksPage />
                    </div>
                    : null}
                <ErrorToast error={errorToast} />
            </div>
        );
    }
}

class SmartPricingSubscribeConfirm extends React.PureComponent {
    render() {
        const { smartPricingData, subscribeSmartPricing } = this.props;
        return (
            <div className="PitchDataContainer" style={{ backgroundColor: '#fff', maxWidth: 500, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ textAlign: 'center', width: '100%' }}>
                    <div style={{ padding: 25, backgroundColor: '#fff', paddingTop: 0 }}>
                        <img src={images} alt="" />
                    </div>
                    <div style={{ margin: 'auto', flexDirection: "column", color: '#000', textAlign: 'center', padding: 10, lineHeight: 1.2, paddingTop: 0 }}>
                        <h2 style={{ fontFamily: 'Brown-Bold' }}>Smart Pricing</h2>
                        {"Smart Pricing Service @ " + smartPricingData.data.revenue_percentage + "% revenue/day will be enabled on your property by clicking on the 'Start Subscription' button below."}
                    </div>

                    <div style={{ padding: '20px 0', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ width: '80%', padding: '3%', backgroundColor: '#fff', borderColor: 'rgba(0,0,0,0.06)', borderStyle: 'solid', borderWidth: 1, borderRadius: 4 }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }} >
                                            <img src={checkmark} alt="" height="8" style={{ opacity: 0.7 }} />
                                        </td>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "left", padding: '5px', fontSize: 14, lineHeight: 1.2 }} >{"Get the power to change room prices for -40% to +15% for today & next 7 days"}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }} >
                                            <img src={checkmark} alt="" height="8" style={{ opacity: 0.7 }} />
                                        </td>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "left", padding: '5px', fontSize: 14, lineHeight: 1.2 }} >{"View average prices of other properties in your area"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <img src={smart_pricing} alt="" width="60%" style={{ marginBottom: 15 }} />
                    <div style={{ height: 50, width: '100%', margin: 'auto', backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: 10 }}>
                        <button onClick={() => subscribeSmartPricing()} style={{ color: '#fff', fontSize: 15, fontWeight: 10, height: '100%', borderRadius: 100, padding: 10, alignItems: 'center', justifyContent: 'center', backgroundImage: 'linear-gradient(to right, #d11450, #ef4123)', paddingLeft: 20, paddingRight: 20, fontFamily: 'Brown-Bold', cursor: 'pointer' }}>
                            Start Subscription
                        </button>
                    </div>
                    <div style={{ height: 25, flexWrap: "wrap", paddingBottom: 20, backgroundColor: '#fff', margin: 'auto', color: 'rgba(0,0,0,0.54)', width: '100%', textAlign: 'center' }}>
                        {"Cancel anytime after " + smartPricingData.data.cancellation_policy.days + " days"}
                    </div>
                </div>
            </div>
        )
    }
}