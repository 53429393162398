export const initialState = {
  userData: {
    data: {},
    isFetching: false,
    error: ''
  },
  formData: {
    data: {},
    isFetching: false,
    error: '',
    gateway: ''
  },
  userFormDetails: {}
};
