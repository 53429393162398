import React, { Fragment } from 'react';
import { Alert, InputNumber } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.scss';
import * as images from '../../assets/images/oyo-logo-white.png';
import { getNestedValue } from '../../utils/objectNesting';
import ReactGA from 'react-ga';
import gaConstants from '../../constants/gaConstants';
import { BOLETO_URL } from '../../config/constant';
import { PAY_LABELS, GATEWAY_LIST } from './constant';
import { locale } from '../../constants/apiConstants';
import get from 'lodash/get';
import ErrorToast from '../../components/ErrorToast';

export default class PaymentRender extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const prevPaymentData = getNestedValue(prevProps, 'paymentData', 'object');
    const paymentData = getNestedValue(this.props, 'paymentData', 'object');

    if (
      paymentData.isFetching != prevPaymentData.isFetching &&
      !paymentData.isFetching &&
      !paymentData.error &&
      paymentData.data &&
      paymentData.data.gateway_url &&
      paymentData.data.data
    ) {
      const { pathname, search } = getNestedValue(this.props, 'location', 'object');
      const txnid = getNestedValue(paymentData, 'data.data.txnid');
      if (pathname && search) {
        localStorage.setItem('orderUrl', pathname + search);
        localStorage.setItem('pageMode', this.props.pageMode);
      }
      if (txnid) {
        localStorage.setItem('txnid', txnid);
      }
      document.getElementById('js-payment-redirect').submit();
    }
  }

  handleSubmit = payment_gateway => {
    ReactGA.event({
      category: gaConstants.categories.SECURE,
      action: gaConstants.actions.secure.PAY_NOW,
      label: this.props.hotel_id,
      payment_gateway
    });
    this.props.initiatePayment(payment_gateway);
  };

  handleClick = () => {
    ReactGA.event({
      category: gaConstants.categories.SECURE,
      action: gaConstants.actions.secure.RECORD_OFFLINE_PAYMENT,
      label: this.props.hotel_id
    });
    this.props.history.push('/payment/offline?payment_data=' + this.props.paymentUserData);
  };

  handleBoleto = () => {
    ReactGA.event({
      category: gaConstants.categories.BOLETO,
      action: gaConstants.actions.boleto.BOLETO_PAYMENT,
      label: this.props.hotel_id
    });

    const { paymentUserData, source, pageMode } = this.props;
    this.props.history.push(`${BOLETO_URL}/${paymentUserData}?source=${source}&pageMode=${pageMode}`);
  };

  handleXendit = () => {
    const { paymentValue, paymentUserData, source, pageMode } = this.props;

    ReactGA.event({
      category: gaConstants.categories.XENDIT,
      action: gaConstants.actions.xendit.XENDIT_PAYMENT,
      label: this.props.hotel_id
    });

    this.props.fetchOtherGatewayData({
      amount: parseInt(paymentValue),
      payment_data: paymentUserData,
      payment_gateway: GATEWAY_LIST['xendit'],
      source,
      page_mode: pageMode
    });
  };

  renderButton = () => {
    const { gatewayData } = this.props;
    const gatewayList = getNestedValue(gatewayData, 'data.data', 'array');

    return (
      <Fragment>
        {gatewayList.map(({ type }) => {
          if (type === 'PAYU' || type === 'ADYEN') {
            return this.renderOnlineButton(type);
          } else if (type === 'BOLETO') {
            return this.renderBuletoButton();
          } else if (type == GATEWAY_LIST['xendit']) {
            return this.renderXenditButton();
          }
          return null;
        })}
        {this.renderOfflineButton()}
      </Fragment>
    );
  };

  renderOnlineButton = payment_gateway => {
    const { disabled, paymentType, screenMetaData } = this.props;
    const labels =
      getNestedValue(screenMetaData, 'data.screen_labels.oyo-secure-web.SECURE_WEB_BUTTON_ONLINE') || 'Pay Now';

    if (paymentType != 'OFFLINE') {
      return (
        <div key="online" className="receipt__buttons">
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => this.handleSubmit(payment_gateway)}
            className={disabled ? 'receipt__submitButtonDisabled' : 'receipt__submitButton'}
            disabled={disabled}
            type="button">
            {labels}
          </button>
        </div>
      );
    }
    return null;
  };

  renderBuletoButton = () => {
    const labels =
      getNestedValue(this.props, 'screenMetaData.data.screen_labels.oyo-secure-web.SECURE_BOLETO_BUTTON') ||
      'Pay using Boleto';
    return (
      <div key="buleto" className="receipt__buttons">
        <button onClick={this.handleBoleto} className="receipt__submitButton" type="button">
          {labels}
        </button>
      </div>
    );
  };

  renderXenditButton = () => {
    const labels =
      getNestedValue(this.props, 'screenMetaData.data.screen_labels.oyo-secure-web.SECURE_XENDIT_BUTTON') ||
      'Pay using Xendit';
    return (
      <div key="xendit" className="receipt__buttons">
        <button onClick={this.handleXendit} className="receipt__submitButton" type="button">
          {labels}
        </button>
      </div>
    );
  };

  renderOfflineButton = () => {
    const { pageMode, screenMetaData, paymentType } = this.props;
    const labels = getNestedValue(screenMetaData, 'data.screen_labels.oyo-secure-web.SECURE_WEB_BUTTON_OFFLINE');

    if (pageMode === 'WALLET' && paymentType !== 'ONLINE') {
      return (
        <div key="offline" className="receipt__buttons">
          <button className="receipt__offlinePaymentButton" onClick={this.handleClick} type="button">
            {labels || 'Record an offline payment'}
          </button>
        </div>
      );
    }
    return null;
  };

  getError = () => {
    const listError =
      getNestedValue(this.props.screenMetaData, 'data.screen_labels.oyo-secure-web.SECURE_GATEWAY_ERROR') ||
      PAY_LABELS['listErr'];
    const otherGateWayError = this.props.otherGatewayData['error'];

    if (otherGateWayError) {
      return otherGateWayError;
    } else if (this.props.gatewayData['error']) {
      return listError;
    } else {
      return null;
    }
  };

  currencyParser = val => {
    try {
      // for when the input gets clears
      if (typeof val === 'string' && !val.length) {
        val = '0';
      }

      // detecting and parsing between comma and dot
      const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
      let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
      //  => 1232.21 €

      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, '');

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {
      paymentValue,
      disabled,
      paymentData,
      pageMode,
      recharge_type,
      screenMetaData,
      paymentUserData,
      otherGatewayData,
      currencySymbol,
      setPaymentValue,
      gatewayData,
      hotelInfoData
    } = this.props;
    let screenLabels = getNestedValue(screenMetaData, 'data.screen_labels.oyo-secure-web', 'object');

    const { data: paymentResponse, isFetching, error } = paymentData;

    const hotelInformation = get(hotelInfoData, 'data', {});
    const hotelName = get(hotelInformation, 'name', '');
    const hotelOyoId = get(hotelInformation, 'oyo_id', '');
    let hotelAddress = [];
    const hotelCity = get(hotelInformation, 'city', '');
    const hotelCountry = get(hotelInformation, 'country', '');

    if (hotelCity) {
      hotelAddress.push(hotelCity);
    }
    if (hotelCountry) {
      hotelAddress.push(hotelCountry);
    }

    const gatewayError = this.getError();
    const errorMsg = getNestedValue(paymentData, 'error.message') || 'Something went wrong';

    return (
      <div className={`mainContainer ${this.props.isHms ? 'mainContainer_center_children' : ''}`}>
        {(isFetching || otherGatewayData['isFetching']) && (
          <div className="mainContainer_loader">
            <LoadingOutlined style={{ fontSize: 60, color: '#fff' }} />
          </div>
        )}

        <div className={`receipt ${this.props.isHms ? 'receipt__no_border_center_child' : ''}`}>
          {!this.props.isHms && (
            <div className="receipt__header">
              <img src={images} className="receipt__header--logo" />
            </div>
          )}
          {Object.keys(hotelInformation).length ? (
            <div className="receipt__hotel_detail">
              <div className="receipt__hotel_detail--hotelName">{`${hotelName} (${hotelOyoId})`}</div>
              <div className="receipt__hotel_detail--hotelAddress">{hotelAddress.join(', ')}</div>
            </div>
          ) : null}
          <div className={`receipt__heading ${this.props.isHms ? 'receipt__heading_hms' : ''}`}>
            {getNestedValue(screenLabels, 'SECURE_WEB_TITLE_HOME_PAGE') || 'PAYMENT INFORMATION'}
          </div>
          <div className="receipt__paymentAmount">
            Amount
            <br />
            <form onSubmit={this.handleSubmit} id="js-payment-merchant">
              <InputNumber
                min={0}
                name="amount"
                value={paymentValue}
                onChange={setPaymentValue}
                disabled={true}
                className={'receipt__amtInputDisabled'}
                formatter={value => `${currencySymbol} ${value}`}
                parser={this.currencyParser}
              />
              <input type="hidden" name="payment_data" value={paymentUserData} />
              {this.renderButton()}
            </form>
          </div>

          {gatewayError && (
            <div className="receipt__errorContainer" style={{ marginTop: -10 }}>
              <Alert message="Error" description={gatewayError} type="error" showIcon />
            </div>
          )}

          {error ? (
            <div className="receipt__errorContainer">
              <Alert message="Error" description={errorMsg} type="error" showIcon />
            </div>
          ) : paymentResponse && paymentResponse.gateway_url && paymentResponse.data ? (
            <form action={paymentResponse.gateway_url} id="js-payment-redirect" method="POST">
              {Object.entries(paymentResponse.data).map(([key, value]) => {
                let keyValue = value;
                if (key == 'pg') {
                  keyValue = '';
                }
                return <input type="hidden" key={key} name={key} value={keyValue} />;
              })}
              <input type="submit" value="submit" style={{ display: 'none' }} />
            </form>
          ) : null}

          {!this.props.isHms && (
            <>
              <div className="receipt__divider"></div>
              <div className="receipt__footer">
                <strong>Oravel Stays Private Limited</strong>
                <br />
                <strong>Reg. office: </strong> Delhi Rectangle Regus, Level 4,Rectangle 1,Commercial Complex D-4,Saket ,
                New Delhi -110017
              </div>
            </>
          )}
        </div>
        <ErrorToast error={this.props.error} style={{top: '30%'}} updateDuration={5000} horizontalPosition={'center'}/>
      </div>
    );
  }
}
