import * as actionTypes from "./actionTypes";
import { OfflinePaymentState as initialState } from "./state";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PAYMENT_MODES:
      return {
        ...state,
        paymentModes: {
          data: action.payload,
          error: '',
          isFetching: false,
        },
      };

    case actionTypes.START_PAYMENT_MODES:
      return {
        ...state,
        paymentModes: {
          ...state.paymentModes,
          isFetching: true,
          error: '',
        },
      };

    case actionTypes.ERROR_PAYMENT_MODES:
      return {
        ...state,
        paymentModes: {
          ...state.paymentModes,
          isFetching: false,
          error: action.payload,
        },
      };
    
    case actionTypes.SAVE_RECORD_PAYMENT:
      return {
        ...state,
        recordPayment: {
          data: action.payload,
          error: false,
          isFetching: false,
        },
      };

    case actionTypes.START_RECORD_PAYMENT:
      return {
        ...state,
        recordPayment: {
          ...state.recordPayment,
          isFetching: true,
          error: false,
        },
      };

    case actionTypes.ERROR_RECORD_PAYMENT:
      return {
        ...state,
        recordPayment: {
          ...state.recordPayment,
          isFetching: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
