import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import './style/style.scss';
import Header from './component/Header/index';
import Price from './component/Price';
import BoletoFormCard from './component/Form';
import { BoletoContext } from './context';
import ErrorBoxComponent from '../common/errorBoxComponent';
import { getNestedValue } from '../../utils/objectNesting';

const BoletoRender = () => {
  const { boletoData } = useContext(BoletoContext);
  const userError = getNestedValue(boletoData, 'userData.error');

  return (
    <Row className="container">
      <Col
        className="card"
        xs={{ span: 24 }}
        sm={{ span: 18, offset: 3 }}
        md={{ span: 18, offset: 3 }}
        lg={{ span: 10, offset: 7 }}
      >
        <Header />
        <Price />
        {userError ? (
          <ErrorBoxComponent error={userError} />
        ) : (
            <BoletoFormCard />
          )}
      </Col>
    </Row>
  );
}

export default BoletoRender;
