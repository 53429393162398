import React from 'react';
import '../style.css';

export default class ThanksPage extends React.PureComponent {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#fff', padding: 20, borderRadius: 6, borderColor: 'rgba(0,0,0,0.06)', borderWidth: 1, fontFamily: 'Brown-Bold' }}>
                    <h2 style={{fontFamily: 'Brown-Bold'}}>Hotel Website</h2>
                    <h3>has been successfully activated on your property.</h3>
                </div>
            </div>
        );
    }
}