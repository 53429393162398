import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi';
import { HMSBFF_HOST, locale } from '../../constants/apiConstants';

export function startFetchingBoletoUserData() {
  return {
    type: actionTypes.START_FETCHING_BOLETO_USER_DATA
  };
}

export function saveBoletoUserData(payload) {
  return {
    type: actionTypes.SAVE_BOLETO_USER_DATA,
    payload: payload
  };
}

export function errorFetchingBoletoUserData(error) {
  return {
    type: actionTypes.ERROR_FETCHING_BOLETO_USER_DATA,
    payload: error
  };
}

export function fetchBoletoUserData(params) {
  const headers = {
    Authorization: "secure_authorization",
    locale: locale,
  };

  return dispatch => {
    dispatch(startFetchingBoletoUserData());
    RESTApi.GET(
      `${HMSBFF_HOST}/owner_ms/api/v1/payments/user-details`,
      params,
      data => {
        dispatch(saveBoletoUserData(data));
      },
      error => {
        dispatch(errorFetchingBoletoUserData(error));
      },
      headers
    );
  };
}

export function startFetchingBoletoFormData(payload) {
  return {
    type: actionTypes.START_FETCHING_BOLETO_FORM_DATA,
    payload: payload
  };
}

export function saveBoletoFormData(payload) {
  return {
    type: actionTypes.SAVE_BOLETO_FORM_DATA,
    payload: payload
  };
}

export function errorFetchingBoletoFormData(error) {
  return {
    type: actionTypes.ERROR_FETCHING_BOLETO_FORM_DATA,
    payload: error
  };
}

export function fetchBoletoFormData(body) {
  const headers = {
    Authorization: "secure_authorization",
    locale: locale
  };

  const { payment_gateway } = body;

  return dispatch => {
    dispatch(startFetchingBoletoFormData(payment_gateway));
    RESTApi.POST(
      `${HMSBFF_HOST}/owner_ms/api/v1/payments/initiate`,
      null,
      body,
      data => {
        if (!data['status']) {
          const msg = data['message'] || 'Please check fields again'
          dispatch(errorFetchingBoletoFormData(msg));
        } else {
          dispatch(saveBoletoFormData(data));
        }
      },
      error => {
        dispatch(errorFetchingBoletoFormData(error));
      },
      headers
    );
  };
}

export function saveUserFormDetails(payload) {
  return {
    type: actionTypes.SAVE_USER_FORM_DETAILS,
    payload
  };
}
