import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi'

export function saveRanking(payload) {
    return {
        type: actionTypes.SAVE_RANKING,
        payload: payload
    }
}

export function startFetchingRanking() {
    return {
        type: actionTypes.START_FETCHING_RANKING,
    }
}

export function errorFetchingRanking(error) {
    return {
        type: actionTypes.ERROR_FETCHING_RANKING,
        payload: error
    }
}

export function fetchRanking(params) {
    const headers = {
        access_token: "access_token"
    }
    return (dispatch) => {
        dispatch(startFetchingRanking());
        RESTApi.GET('/owner_ms/api/v1/vas/sales/web/ranking/get_pitch', params, (data) => {
            if (data)
                dispatch(saveRanking(data))
        }, (error) => {
            dispatch(errorFetchingRanking(error))
        }, headers)
    }
}

export function saveSubscribedRanking(payload) {
    return {
        type: actionTypes.SAVE_SUBSCRIBED_RANKING,
        payload: payload
    }
}

export function startSubscribingRanking() {
    return {
        type: actionTypes.START_SUBSCRIBING_RANKING,
    }
}

export function errorSubscribingRanking(error) {
    return {
        type: actionTypes.ERROR_SUBSCRIBING_RANKING,
        payload: error
    }
}

export function subscribeRanking(params, data) {
    const headers = {
        access_token: "access_token"
    }
    return (dispatch) => {
        dispatch(startSubscribingRanking())
        RESTApi.POST('/owner_ms/api/v1/vas/sales/web/ranking/create', params, data, (data) => {
            if (data)
                dispatch(saveSubscribedRanking(data))
        }, (error) => {
            dispatch(errorSubscribingRanking(error))
        }, headers)
    }
}
