import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import VisibilityBoostRender from './render';
import { bindActionCreators } from "redux";
import * as Actions from './actions';
import parseQueryParams from '../../utils/QueryParamsParser';
import ReactGA from 'react-ga';
import gaConstants from '../../constants/gaConstants';

class VisibilityBoostScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: null
    };
    console.log('visibility index',props)
    this.subscribeVisibilityBoost = this.subscribeVisibilityBoost.bind(this)
    this.getJsonFromUrl = this.getJsonFromUrl.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.visibilityBoostData.data).length == 0) {
      const { match } = this.props;
      let params = {
        token: decodeURIComponent(match.params.token)
      };
      this.props.actions.fetchVisibilityBoost(params);
      ReactGA.pageview(gaConstants.pages.BOOST);
      this.getJsonFromUrl();
    }
  }

  getJsonFromUrl() {
    const result = parseQueryParams(this.props.location.search);
    this.setState({ queryParams: result });
  }

  subscribeVisibilityBoost() {
    const { match } = this.props;
    const { queryParams } = this.state;
    let params = {
      token: decodeURIComponent(match.params.token),
      source: (queryParams && queryParams.source && queryParams.source != "undefined") ? queryParams.source : "OWNER_PCS_CRM"
    };
    this.props.actions.subscribeVisibilityBoost(params);
    ReactGA.event({
      category: gaConstants.categories.BOOST,
      action: gaConstants.actions.boost.SUBSCRIBED
    });
  }

  render() {
    const { visibilityBoostData, visibilityBoostSubscriptionData } = this.props;
    return (
      <VisibilityBoostRender
        visibilityBoostData={visibilityBoostData}
        visibilityBoostSubscriptionData={visibilityBoostSubscriptionData}
        subscribeVisibilityBoost={this.subscribeVisibilityBoost}
        queryParams={this.state.queryParams}
        errorToast={this.state.errorToast}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
};

const mapStateToProps = state => ({
  visibilityBoostData: state.visibilityBoostReducer.visibilityBoostData,
  visibilityBoostSubscriptionData: state.visibilityBoostReducer.visibilityBoostSubscription,
});

export default connect(mapStateToProps, mapDispatchToProps)(VisibilityBoostScreen);