import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi'

export function saveSmartPricing(payload) {
    return {
        type: actionTypes.SAVE_SMART_PRICING,
        payload: payload
    }
}

export function startFetchingSmartPricing() {
    return {
        type: actionTypes.START_FETCHING_SMART_PRICING,
    }
}

export function errorFetchingSmartPricing(error) {
    return {
        type: actionTypes.ERROR_FETCHING_SMART_PRICING,
        payload: error
    }
}

export function fetchSmartPricing(params) {
    const headers = {
        access_token: "access_token"
    }
    return (dispatch) => {
        dispatch(startFetchingSmartPricing());
        RESTApi.GET('/owner_ms/api/v1/vas/sales/web/pricing/get_pitch', params, (data) => {
            if (data)
                dispatch(saveSmartPricing(data))
        }, (error) => {
            dispatch(errorFetchingSmartPricing(error))
        }, headers)
    }
}

export function saveSubscribedSmartPricing(payload) {
    return {
        type: actionTypes.SAVE_SUBSCRIBED_SMART_PRICING,
        payload: payload
    }
}

export function startSubscribingSmartPricing() {
    return {
        type: actionTypes.START_SUBSCRIBING_SMART_PRICING,
    }
}

export function errorSubscribingSmartPricing(error) {
    return {
        type: actionTypes.ERROR_SUBSCRIBING_SMART_PRICING,
        payload: error
    }
}

export function subscribeSmartPricing(params, data) {
    const headers = {
        access_token: "access_token"
    }
    return (dispatch) => {
        dispatch(startSubscribingSmartPricing())
        RESTApi.POST('/owner_ms/api/v1/vas/sales/web/pricing/create', params, data, (data) => {
            if (data)
                dispatch(saveSubscribedSmartPricing(data))
        }, (error) => {
            dispatch(errorSubscribingSmartPricing(error))
        }, headers)
    }
}
