import { combineReducers } from 'redux';
import instantAdvancesReducer from '../modules/InstantAdvances/reducer';
import homeReducer from '../modules/Home/reducer';
import plbReducer from '../modules/PLB/reducer'
import paymentReducer from '../modules/Payment/reducer'
import whatsappOptinReducer from '../modules/WhatsappOptin/reducer'
import visibilityBoostReducer from '../modules/VisibilityBoost/reducer'
import websiteReducer from '../modules/PublicPagesWebsite/reducer'
import reportReducer from '../modules/PublicPagesReport/reducer'
import smartPricingReducer from '../modules/SmartPricing/reducer'
import rankingReducer from '../modules/Ranking/reducer';
import offlinePaymentReducer from '../modules/OfflinePayment/reducer'
import boletoReducer from '../modules/Boleto/reducer'

export default combineReducers({
    instantAdvancesReducer: instantAdvancesReducer,
    homeReducer: homeReducer,
    plbReducer: plbReducer,
    paymentReducer: paymentReducer,
    whatsappOptin: whatsappOptinReducer,
    visibilityBoostReducer: visibilityBoostReducer,
    websiteReducer: websiteReducer,
    reportReducer: reportReducer,
    smartPricingReducer: smartPricingReducer,
    rankingReducer: rankingReducer,
    offlinePaymentReducer: offlinePaymentReducer,
    boletoReducer
});