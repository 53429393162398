export const PLBState = {
    plbData: {
      data: {},
      isFetching: false,
      error: false
    },
    plbSubscription: {
      data: {},
      isFetching: false,
      error: false,
    }
  }