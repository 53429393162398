import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import _ from 'lodash';
import ReactGA from 'react-ga';

import { BoletoContext } from './context';
import BoletoRender from './BoletoRender';
import { getNestedValue } from '../../utils/objectNesting';
import Base64 from "../../utils/base64";
import { getCurrencySymbol } from '../../utils/getCurrencySymbol';
import { fetchBoletoUserData, fetchBoletoFormData, saveUserFormDetails } from './actions';
import { fetchScreenMetadata } from '../Payment/actions';
import parseQueryParams from '../../utils/QueryParamsParser';
import gaConstants from '../../constants/gaConstants';

class BoletoScreen extends Component {
  constructor(props) {
    super(props);
    const params = this.getTokenData();
    let source = 'others';
    let pageMode = '';
    try {
      const result = parseQueryParams(props.location.search);
      source = result['source'];
      pageMode = result['pageMode'];
    } catch (e) { }
    this.state = {
      ...params,
      source,
      pageMode
    }
  }

  componentDidMount() {
    const metaData = getNestedValue(this.props, 'screenMetaData.data', 'object');
    const boletoUserData = getNestedValue(this.props, 'boletoData.userData.data', 'object');
    if (_.isEmpty(metaData)) {
      this.props.actions.fetchScreenMetadata({ features: 'oyo-secure-web' });
    }

    if (_.isEmpty(boletoUserData)) {
      this.props.actions.fetchBoletoUserData({
        payment_data: getNestedValue(this.props, 'match.params.token', 'string'),
        payment_gateway: 'BOLETO',
        page_mode: this.state.pageMode
      });
    }
  }

  componentDidUpdate(prevProps) {
    //   const prevToken = getNestedValue(prevProps, 'match.params.token', 'string');
    //   const token = getNestedValue(this.props, 'match.params.token', 'string');
    //   if (prevToken !== token) {
    //     const params = this.getTokenData();
    //     this.setState({ ...params });
    //   }

    const prevBoletUrl = getNestedValue(prevProps, 'boletoData.formData.data.data.url', 'string');
    const boletUrl = getNestedValue(this.props, 'boletoData.formData.data.data.url', 'string');

    if ((prevBoletUrl !== boletUrl) && boletUrl) {
      window.location.href = boletUrl;
      ReactGA.event({
        category: gaConstants.categories.BOLETO,
        action: gaConstants.actions.boleto.BOLETO_PDF_DOWNLOAD
      });
    }
  }

  getTokenData = () => {
    const token = getNestedValue(this.props, 'match.params.token', 'string');
    const decodedKey = Base64.atob(token).split(":");
    const currency = decodedKey[5] || 'INR'
    return {
      amount: decodedKey[1] || 0,
      currency: getCurrencySymbol(currency),
      rechargeType: decodedKey[2]
    }
  }

  handleAmountChange = ({ target: { value } }) => {
    this.setState({
      amount: value
    });
  }

  initiatePayment = (user_params) => {
    ReactGA.event({
      category: gaConstants.categories.BOLETO,
      action: gaConstants.actions.boleto.BOLETO_FORM_SUBMIT
    });

    this.props.actions.fetchBoletoFormData({
      amount: this.state.amount,
      payment_data: getNestedValue(this.props, 'match.params.token', 'string'),
      payment_gateway: 'BOLETO',
      source: this.state.source,
      user_params,
      page_mode: this.state.pageMode
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { amount, currency, rechargeType } = this.state;
    const { boletoData, screenMetaData, actions: { saveUserFormDetails } } = this.props;

    return (
      <div>
        <BoletoContext.Provider
          value={{
            amount,
            currency,
            onAmountChange: this.handleAmountChange,
            boletoData,
            metaData: getNestedValue(screenMetaData, 'data.screen_labels.oyo-secure-web', 'object'),
            onInitiatePayment: this.initiatePayment,
            saveUserFormDetails: saveUserFormDetails,
            onGoBack: this.goBack,
            rechargeType
          }}
        >
          <BoletoRender />
        </BoletoContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  boletoData: state.boletoReducer,
  screenMetaData: state.paymentReducer.screenMetaData
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({
      fetchBoletoUserData,
      fetchScreenMetadata,
      fetchBoletoFormData,
      saveUserFormDetails
    },
      dispatch)
  }
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BoletoScreen)
);
