import React from 'react';
import './style.css';
import {connect} from 'react-redux';
import InstantAdvancesRender from './render';
import {bindActionCreators} from "redux";
import * as Actions from './actions';
import ReactGA from 'react-ga';
import gaConstants from '../../constants/gaConstants';
import parseQueryParams from '../../utils/QueryParamsParser';

class InstantAdvancesScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            errorToast: {}
        };
        this.applyNow = this.applyNow.bind(this);
        this.submitDetails = this.submitDetails.bind(this);
        this.fetchRenovationPitchData = this.fetchRenovationPitchData.bind(this);
        this.setSubmitDocumentError = this.setSubmitDocumentError.bind(this);
        this.getJsonFromUrl = this.getJsonFromUrl.bind(this);
    }
    componentDidMount() {
        this.fetchRenovationPitchData();
        ReactGA.pageview(gaConstants.pages.INSTANT_ADVANCE);
        this.getJsonFromUrl();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { lockLoanData, submitDetailsData } = this.props;
        if((lockLoanData !== prevProps.lockLoanData && lockLoanData.error) ||
            (submitDetailsData !== prevProps.submitDetailsData && submitDetailsData.error)) {
            this.setState({
                errorToast: {
                    timestamp: new Date(),
                    text: "Some Error Occurred. Please try again later."
                }
            })
        }
        if (lockLoanData !== prevProps.lockLoanData && lockLoanData.success) {
            this.fetchRenovationPitchData();
        }
        if (submitDetailsData !== prevProps.submitDetailsData && submitDetailsData.success) {
            this.fetchRenovationPitchData();
        }
    }
    
    getJsonFromUrl() {
        const result = parseQueryParams(this.props.location.search);
        this.setState({ queryParams: result });
    }

    fetchRenovationPitchData() {
        const { match } = this.props;
        let params = {
            hotel_id: decodeURIComponent(match.params.hotel_id)
            // hotel_id: "pjmg1z45gwLW24EAtfU85w"
        };
        this.props.actions.fetchPitchData(params);
    }
    applyNow() {
        const { match, pitchData } = this.props;
        const { queryParams } = this.state;
        let params = {
            hotel_id: decodeURIComponent(match.params.hotel_id),
            months: pitchData.data.months,
            amount: pitchData.data.amount,
            source: queryParams && queryParams.source
        };
        this.props.actions.lockLoan(params);
        ReactGA.event({
            category: gaConstants.categories.INSTANT_ADVANCE,
            action: gaConstants.actions.instant_advance.LOCKED_LOAN
        });
    }
    submitDetails() {
        const { pitchData, match } = this.props;
        let invalidFlag = false;
        if (!invalidFlag && pitchData.data && pitchData.data.aadhar && pitchData.data.pdc) {
            for(let i = 0; i < pitchData.data.months; i++ ) {
                if (!pitchData.data.pdc["pdc"+(i+1)]) {
                    this.setSubmitDocumentError();
                    invalidFlag = true;
                    break;
                }
            }
            if(!invalidFlag && pitchData.data.aadhar.front && pitchData.data.aadhar.back) {
                let params = {
                    hotel_id: decodeURIComponent(match.params.hotel_id)
                };
                this.props.actions.submitDetails(params);
                ReactGA.event({
                    category: gaConstants.categories.INSTANT_ADVANCE,
                    action: gaConstants.actions.instant_advance.SUBMIT_DETAILS
                });
            } else
                this.setSubmitDocumentError();
        } else
            this.setSubmitDocumentError();
    }
    setSubmitDocumentError() {
        this.setState({ errorToast: {
                text: "Please upload all documents.",
                timestamp: new Date()
            }
        })
    }
    render() {
        const { pitchData, lockLoanData, submitDetailsData, match } = this.props;
        return (
            <InstantAdvancesRender
                pitchData={pitchData}
                lockLoanData={lockLoanData}
                applyNow={this.applyNow}
                submitDetails={this.submitDetails}
                submitDetailsData={submitDetailsData}
                errorToast={this.state.errorToast}
                hotelId={decodeURIComponent(match.params.hotel_id)}
            />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
};

const mapStateToProps = state => ({
    pitchData: state.instantAdvancesReducer.pitchData,
    lockLoanData: state.instantAdvancesReducer.lockLoanData,
    uploadDocument: state.instantAdvancesReducer.uploadDocument,
    submitDetailsData: state.instantAdvancesReducer.submitDetailsData,
    contractData: state.instantAdvancesReducer.contractData
});

export default connect(mapStateToProps, mapDispatchToProps)(InstantAdvancesScreen);