import * as actionTypes from './actionTypes';
import { VisibilityBoostState as initialState } from './state';

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SAVE_VISIBILITY_BOOST:
            return {
                ...state,
                visibilityBoostData: {
                    data: action.payload.data,
                    error: false,
                    isFetching: false
                }
            }

        case actionTypes.START_FETCHING_VISIBILITY_BOOST:
            return {
                ...state,
                visibilityBoostData: {
                    ...state.visibilityBoostData,
                    isFetching: true,
                    error: false
                }
            }

        case actionTypes.ERROR_FETCHING_VISIBILITY_BOOST:
            return {
                ...state,
                visibilityBoostData: {
                    ...state.visibilityBoostData,
                    isFetching: false,
                    error: action.payload
                    // error: false,
                    // data: BoostData.data
                }
            }

        case actionTypes.START_SUBSCRIBING_VISIBILITY_BOOST:
            return {
                ...state,
                visibilityBoostSubscription: {
                    ...state.visibilityBoostSubscription,
                    isFetching: true,
                    error: false,
                }
            }

        case actionTypes.ERROR_SUBSCRIBING_VISIBILITY_BOOST:
            return {
                ...state,
                visibilityBoostSubscription: {
                    ...state.visibilityBoostSubscription,
                    isFetching: false,
                    error: action.payload,
                },
            }

        case actionTypes.SAVE_SUBSCRIBED_VISIBILITY_BOOST:
            return {
                ...state,
                visibilityBoostSubscription: {
                    data: action.payload,
                    error: false,
                    isFetching: false,
                }
            }

        default: return state

    }
}

export default reducer