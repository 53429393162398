import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';
import './index.css';
import RouterMain from './config/routes';
import { Provider } from 'react-redux'
import configureStore from './store';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-52365165-54');

ReactDOM.render(
    <Provider store={configureStore()}>
        <Router>
            <RouterMain />
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
