import * as actionTypes from './actionTypes';
import { PaymentState as initialState } from './state';
import update from 'immutability-helper';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PAYMENT_DATA:
      return {
        ...state,
        paymentData: {
          data: action.payload,
          error: false,
          isFetching: false
        }
      };

    case actionTypes.START_FETCHING_PAYMENT_DATA:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          isFetching: true,
          error: false
        }
      };

    case actionTypes.ERROR_FETCHING_PAYMENT_DATA:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          isFetching: false,
          error: action.payload
          //error: false,
          //data: paymentData.data
        }
      };

    case actionTypes.ERROR_FETCHING_PAYMENT_CALLBACK:
      return {
        ...state,
        paymentCallback: {
          ...state.paymentCallback,
          isFetching: false,
          error: action.payload
          //error: false,
          //data: paymentCallback.data
        }
      };

    case actionTypes.SAVE_PAYMENT_CALLBACK:
      return {
        ...state,
        paymentCallback: {
          data: action.payload,
          error: false,
          isFetching: false
        }
      };

    case actionTypes.START_FETCHING_PAYMENT_CALLBACK:
      return {
        ...state,
        paymentCallback: {
          ...state.paymentCallback,
          isFetching: true,
          error: false
        }
      };

    case actionTypes.SAVE_SCREEN_METADATA:
      return {
        ...state,
        screenMetaData: {
          data: action.payload,
          error: false,
          isFetching: false
        }
      };

    case actionTypes.START_SCREEN_METADATA:
      return {
        ...state,
        screenMetaData: {
          ...state.screenMetaData,
          isFetching: true,
          error: false
        }
      };

    case actionTypes.ERROR_SCREEN_METADATA:
      return {
        ...state,
        screenMetaData: {
          ...state.screenMetaData,
          isFetching: false,
          error: action.payload
        }
      };

    case actionTypes.SAVE_GATEWAY_MODE_DATA:
      const gatewayInfo = {
        data: action.payload.data?.gateways,
        message: action.payload?.message,
        status: action.payload?.status,
        success: action.payload?.success
      };
      return update(state, {
        gatewayData: {
          data: { $set: gatewayInfo },
          error: { $set: '' },
          isFetching: { $set: false }
        },
        hotelInfoData: {
          data: { $set: action.payload.data?.hotel_data }
        }
      });

    case actionTypes.ERROR_FETCHING_GATEWAY_MODE_DATA:
      return update(state, {
        gatewayData: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      });

    case actionTypes.START_FETCHING_GATEWAY_MODE_DATA:
      return update(state, {
        gatewayData: {
          isFetching: { $set: true }
        }
      });

    default:
      return state;
  }
};

export default reducer;
