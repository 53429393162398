import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import PublicPageWebsiteRender from './render';
import { bindActionCreators } from "redux";
import * as Actions from './actions';
import parseQueryParams from '../../utils/QueryParamsParser';
import ReactGA from 'react-ga';
import gaConstants from '../../constants/gaConstants';

class WebsiteScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    console.log('ghello', props)
    this.state = {
      queryParams: null
    };
    this.subscribeWebsite = this.subscribeWebsite.bind(this)
    this.getJsonFromUrl = this.getJsonFromUrl.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.websiteData.data).length == 0) {
      const { match } = this.props;
      let params = {
        token: decodeURIComponent(match.params.token)
      };
      this.props.actions.fetchWebsite(params);
      ReactGA.pageview(gaConstants.pages.HOTEL_WEBSITE);
      this.getJsonFromUrl();
    }
  }

  getJsonFromUrl() {
    const result = parseQueryParams(this.props.location.search);
    this.setState({ queryParams: result });
  }

  subscribeWebsite() {
    const { match } = this.props;
    const { queryParams } = this.state;
    let params = {
      token: decodeURIComponent(match.params.token),
      source: (queryParams && queryParams.source && queryParams.source != "undefined") ? queryParams.source : "OWNER_PCS_CRM"
    };
    this.props.actions.subscribeWebsite(params);
    ReactGA.event({
      category: gaConstants.categories.HOTEL_WEBSITE,
      action: gaConstants.actions.hotel_website.SUBSCRIBED
    });
  }

  render() {
    const { websiteData, websiteSubscriptionData } = this.props;
    console.log('index', this.props)
    return (
      <PublicPageWebsiteRender
        websiteData={websiteData}
        websiteSubscriptionData={websiteSubscriptionData}
        subscribeWebsite={this.subscribeWebsite}
        queryParams={this.state.queryParams}
        errorToast={this.state.errorToast}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
};

const mapStateToProps = state => ({
  websiteData: state.websiteReducer.websiteData,
  websiteSubscriptionData: state.websiteReducer.websiteSubscription,
});

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteScreen);