export const API_HOST = ''
//export const API_HOST = 'http://172.17.66.219:5001';

export const API_HOST_STAGING = 'https://owners.oyorooms.com';
export const PROPERTY_INSIGHTS_ENDPOINT = "https://property-insights.oyorooms.com";
export const CRS_API_HOST = "https://api.oyorooms.com";
export const HMSBFF_HOST = "https://hms-api.oyorooms.com";

export const APP_KEY = '4bZ-1b6buUKTWMvSpV7s';
export const APP_SECRET = 'LETJWhxcM5gMXxqt55jt';

export const APP_VERSION = '0100001';

export const locale = window.navigator.language.slice(0, 2);