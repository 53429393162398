export const START_FETCHING_BOLETO_USER_DATA = 'START_FETCHING_BOLETO_USER_DATA';
export const ERROR_FETCHING_BOLETO_USER_DATA = 'ERROR_FETCHING_BOLETO_USER_DATA';
export const SAVE_BOLETO_USER_DATA = 'SAVE_BOLETO_USER_DATA';
export const FETCH_BOLETO_USER_DATA = 'FETCH_BOLETO_USER_DATA';

export const START_FETCHING_BOLETO_FORM_DATA = 'START_FETCHING_BOLETO_FORM_DATA';
export const ERROR_FETCHING_BOLETO_FORM_DATA = 'ERROR_FETCHING_BOLETO_FORM_DATA';
export const SAVE_BOLETO_FORM_DATA = 'SAVE_BOLETO_FORM_DATA';
export const FETCH_BOLETO_FORM_DATA = 'FETCH_BOLETO_FORM_DATA';

export const SAVE_USER_FORM_DETAILS = 'SAVE_USER_FORM_DETAILS';
