import update from 'immutability-helper';
import { initialState } from './state';
import * as actionTypes from './actionTypes';
import { getNestedValue } from '../../utils/objectNesting';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_BOLETO_USER_DATA:
      const userDetails = getNestedValue(action.payload, 'data.user_details', 'object');

      return update(state, {
        userData: {
          data: { $set: action.payload },
          error: { $set: '' },
          isFetching: { $set: false }
        },
        userFormDetails: { $set: userDetails }
      });

    case actionTypes.ERROR_FETCHING_BOLETO_USER_DATA:
      {
        let errorMsg = action.payload;
        if (typeof (errorMsg) == 'object') {
          errorMsg = errorMsg['message'] || 'Something went wrong'
        }
        return update(state, {
          userData: {
            error: { $set: errorMsg },
            isFetching: { $set: false }
          }
        });
      }

    case actionTypes.START_FETCHING_BOLETO_USER_DATA:
      return update(state, {
        userData: {
          isFetching: { $set: true }
        }
      });

    case actionTypes.SAVE_BOLETO_FORM_DATA:
      return update(state, {
        formData: {
          data: { $set: action.payload },
          error: { $set: '' },
          isFetching: { $set: false }
        }
      });

    case actionTypes.ERROR_FETCHING_BOLETO_FORM_DATA:
      {
        let errorMsg = action.payload;
        if (typeof (errorMsg) == 'object') {
          errorMsg = errorMsg['message'] || 'Something went wrong'
        }

        return update(state, {
          formData: {
            error: { $set: errorMsg },
            isFetching: { $set: false }
          }
        });
      }

    case actionTypes.START_FETCHING_BOLETO_FORM_DATA:
      return update(state, {
        formData: {
          isFetching: { $set: true },
          error: { $set: '' },
          gateway: { $set: action.payload }
        }
      });

    case actionTypes.SAVE_USER_FORM_DETAILS:
      return update(state, {
        userFormDetails: { $set: action.payload }
      });

    default:
      return state;
  }
};

export default reducer;
