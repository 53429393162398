import React from 'react';
import './style.css';
import UploadBox from '../UploadBox';

export default class UploaderSection extends React.PureComponent {
    state = {
        isChecked:false,
        html:'',
    };
    
    checkBoxColor=(isChecked)=>{
        this.setState({isChecked:!isChecked});
    };
    render() {
        const { data, submitDetails, hotelId } = this.props;
        return (
            <div className="UploaderSectionContainer" >
                <div className="Header">
                    <h2 className="HeaderText">Instant Advances</h2>
                </div>
                <div className="RequestedDataContainer">
                    <div className="RequestedAmountText">Your Requested Amount</div>
                    <div className="RequestedAmount" style={{ letterSpacing: '2q' }}>
                        {data.amount ? ("₹" + data.amount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")) : ""}
                    </div>
                    <div className="RequestedDataSeparator" />
                    <div className="DurationText">
                        {data.months ? ("Duration: " + data.months + (data.months===1?" Month":" Months")) : ""}
                    </div>
                    <div className="ServiceIconContainer">
                        <img
                            src={process.env.PUBLIC_URL + '/assets/images/renovation_logo.png'}
                            alt="" style={{ height: '100%', width: '100%' }}
                        />
                    </div>
                </div>
                <div className="UploadInformationText">
                    {"Kindly upload signed cheques from the name of " +
                        (data.legalEntity ? data.legalEntity : "") +
                        " addressed to \"Oravel Stays Pvt Ltd\" with below EMI amount & dates."
                    }
                </div>
                <div className="SampleCheque">Sample Cheque</div>
                <div className="Cheque">
                    <img
                        src={data.sample_cheque_url ? data.sample_cheque_url : "https://s3-ap-southeast-1.amazonaws.com/ownerapp/renovation_advance/sample_cheque.jpg"}
                        alt="Sample Cheque"
                        style={{ height: '100%', width: '100%', borderRadius: 3 }}
                    />
                </div>
                <div className="UploadChequeSection">
                    {data.emi_data.map((info, index) => {
                        let pdcIndex = 'pdc' + (index + 1);
                        return (
                            <UploadBox key={index} url={data.pdc[pdcIndex]} type={"PDC"} index={index} data={info} hotelId={hotelId}/>
                        )
                    })}
                </div>
                <div className="UploadAadharText">Upload your Aadhar Card</div>
                <div className="UploadAadharSection">
                    <UploadBox url={data.aadhar.front} type={"AADHAR"} index={0} hotelId={hotelId}/>
                    <UploadBox url={data.aadhar.back} type={"AADHAR"} index={1} hotelId={hotelId}/>
                </div>
                <div className="ConfirmationTextContainer">
                        <label className="checkboxlabel">I agree with the Undertaking towards Capex Amount for Renovation and Refurbishment of the Hotel
                            <input type="checkbox" onClick={() => this.checkBoxColor(this.state.isChecked)} />
                            <span className="checkmark" />
                        </label>
                </div>
                <label className="ReadMore" >
                    <span>
                        <a target="_blank" rel="noopener noreferrer" href={"/#/contract/"+hotelId} style={{ color:'#ee2a24', textDecoration:'none'}} >
                            Read More
                        </a>
                    </span>
                </label>
                <div className="ButtonContainer">
                    <div onClick={ this.state.isChecked ?  submitDetails : null} className= { this.state.isChecked ? "ApplyNowButton" : 'ApplyNowButtonInactive'}  >
                        <div className="ApplyNowText">Send Details</div>
                    </div>
                </div>
            </div>

        );
    }
}
