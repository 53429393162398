export const START_FETCHING_PLB = "START_FETCHING_PLB";
export const ERROR_FETCHING_PLB = "ERROR_FETCHING_PLB";
export const SAVE_PLB = "SAVE_PLB";
// FOR FETCHING WEBSITE_DATA

export const START_FETCHING_PLB_PAST_IMPACT = "START_FETCHING_PLB_PAST_IMPACT";
export const ERROR_FETCHING_PLB_PAST_IMPACT = "ERROR_FETCHING_PLB_PAST_IMPACT";
export const SAVE_PLB_PAST_IMPACT = "SAVE_PLB_PAST_IMPACT";
// FOR FETCHING PLB_PAST_IMPACT

export const START_SUBSCRIBING_PLB = "START_SUBSCRIBING_PLB";
export const ERROR_SUBSCRIBING_PLB = "ERROR_SUBSCRIBING_PLB";
export const SAVE_SUBSCRIBED_PLB = "SAVE_SUBSCRIBED_PLB";
// FOR SUBSCRIBING PLB

export const START_UNSUBSCRIBING_PLB = "START_UNSUBSCRIBING_PLB";
export const ERROR_UNSUBSCRIBING_PLB = "ERROR_UNSUBSCRIBING_PLB";
export const SAVE_UNSUBSCRIBED_PLB = "SAVE_UNSUBSCRIBED_PLB";
// // FOR UNSUBSCRIBING PLB

// export const CLEAR_SUBSCRIBE_UNSUBSCRIBE_ERRORS = "CLEAR_SUBSCRIBE_UNSUBSCRIBE_ERRORS";