export const InstantAdvancesState = {
    pitchData: {
        isFetching: false,
        error: false
    },
    lockLoanData: {
        isFetching: false,
        error: false
    },
    uploadDocument: {
        PDC: { },
        AADHAR: { }
    },
    submitDetailsData: {
        message: "",
        isFetching: false,
        error: false,
    },
    contractData: {
        data: "",
        isFetching: false,
        error: false,
    }
};