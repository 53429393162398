import React from 'react';
import './style.css';
import Spinner from '../../components/Spinner';
import TryAgainLater from '../../components/TryAgainLater';
import ErrorToast from '../../components/ErrorToast';
import * as images from '../../assets/images/boost-icon.png';
import * as boost_rocket from '../../assets/images/boost_rocket.png';
import ThanksPage from './Components/ThanksPage';

export default class VisibilityBoostRender extends React.PureComponent {
    render() {
        console.log('visibility render',this.props)
        const { visibilityBoostData, subscribeVisibilityBoost, errorToast, visibilityBoostSubscriptionData, queryParams } = this.props;
        return (
            <div className="Container">
                {visibilityBoostData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    :
                    Object.keys(visibilityBoostData.data).length && queryParams && queryParams.source ?
                        <VisibilityBoostSubscribeConfirm visibilityBoostData={visibilityBoostData} subscribeVisibilityBoost={subscribeVisibilityBoost} />
                        :
                        <TryAgainLater />
                }
                {visibilityBoostSubscriptionData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    : null
                }
                {visibilityBoostSubscriptionData.data && visibilityBoostSubscriptionData.data.message ?
                    <div className="SpinnerContainer" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
                        <ThanksPage />
                    </div>
                    : null}
                <ErrorToast error={errorToast} />
            </div>
        );
    }
}

class VisibilityBoostSubscribeConfirm extends React.PureComponent {
    render() {
        const { visibilityBoostData, subscribeVisibilityBoost } = this.props;
        return (
            <div className="PitchDataContainer" style={{ backgroundColor: '#fff', maxWidth: 500, justifyContent: 'flex-start', alignItems: 'center', fontFamily: 'Brown-Regular' }}>
                <div style={{ textAlign: 'center', width: '100%' }}>
                    <div style={{ padding: 25, backgroundColor: '#fff' }}>
                        <img src={images} alt="" />
                    </div>
                    <div style={{ margin: 'auto', flexDirection: "column", color: '#000', textAlign: 'center', padding: 10, lineHeight: 1.3, fontSize: 16 }}>
                        <h2 style={{ fontFamily: 'Brown-Bold' }}>Visibility Boost</h2>
                        {visibilityBoostData.data.plan_name.charAt(0).toUpperCase() + visibilityBoostData.data.plan_name.slice(1) + " Visibility Boost @ " + visibilityBoostData.data.revenue_percentage + "% revenue/day will be enabled on your property by clicking on the 'Start Subscription' button below."}
                    </div>
                    <div style={{ height: 48, padding: 10, margin: 'auto', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex', color: 'rgba(0,0,0,0.87)', textAlign: 'center', lineHeight: 1.3, fontSize: 14 }}>
                        {"Increase occupancy by " + visibilityBoostData.data.meta_data.occupancy_delta + "% & revenue by " + visibilityBoostData.data.meta_data.revenue_delta + "%"}
                    </div>
                    <div style={{ padding: '20px 0', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ width: '40%', padding: '3%', marginLeft: '5%', marginRight: '2%', backgroundColor: '#fff', borderColor: 'rgba(0,0,0,0.06)', borderStyle: 'solid', borderWidth: 1, borderRadius: 4 }}>
                            {/* now */}
                            <table style={{ width: '100%', lineHeight: 1.2 }}>
                                <tbody>
                                    <tr>
                                        <td colSpan="2" style={{ height: 2, textAlign: "left" }} >
                                            <div style={{ textAlign: "left", fontSize: 16, color: '#242b2e', fontFamily: 'Brown-Bold' }}>
                                                Now
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "left", padding: '5px', fontSize: 14 }} >Listing Rank(approx)</td>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "right", padding: '5px', fontSize: 14 }} >{visibilityBoostData.data.meta_data.current_rank}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: '#242b2e', opacity: 0.54, textAlign: "left", padding: '5px', alignContent: 'right', fontSize: 14 }}>Customer Views</td>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "right", padding: '5px', fontSize: 14 }} >{visibilityBoostData.data.meta_data.current_views}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ width: '40%', padding: '3%', marginRight: '5%', marginLeft: '2%', backgroundColor: 'rgba(26, 182, 79, 0.1)', borderColor: 'rgba(0,0,0,0.06)', borderStyle: 'solid', borderWidth: 1, borderRadius: 4 }}>
                            <table style={{ width: '100%', lineHeight: 1.2 }}>
                                <tbody>
                                    <tr>
                                        <td colSpan="2" style={{ height: 2, textAlign: "left" }} >
                                            <div style={{ textAlign: "left", fontSize: 16, color: '#242b2e', fontFamily: 'Brown-Bold' }}>
                                                <img src={boost_rocket} alt="" style={{ width: 10, paddingRight: 7 }} />
                                                With Boost
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "left", padding: '5px', fontSize: 14 }} >Listing Rank(approx)</td>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "right", padding: '5px', fontSize: 14 }} >{visibilityBoostData.data.meta_data.new_rank}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "left", padding: '5px', fontSize: 14 }} >Customer Views</td>
                                        <td style={{ color: '#242b2e', opacity: 0.54, height: 2, textAlign: "right", padding: '5px', fontSize: 14 }} >{visibilityBoostData.data.meta_data.new_views}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#fff', width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <div style={{ height: 20, padding: 10, backgroundColor: '#fff', borderColor: 'rgba(0,0,0,0.06)', borderStyle: 'solid', borderWidth: 1, borderRadius: 4, textAlign: 'center', width: 250, margin: 'auto', marginBottom: 10 }}>
                            <b>1000+</b> Hotels have opted in this.
                    </div>
                        <div style={{ flexWrap: "wrap", margin: 'auto', fontSize: 15, color: 'rgba(0,0,0,0.54)', textAlign: 'center', backgroundColor: '#fff', marginBottom: 10, padding: 10 }}>
                            {"This service will be activated @ " + visibilityBoostData.data.revenue_percentage + "% revenue/day."}
                        </div>
                        <div style={{ height: 50, width: '100%', margin: 'auto', backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: 10 }}>
                            <button onClick={() => subscribeVisibilityBoost()} style={{ color: '#fff', fontSize: 15, fontWeight: 10, height: '100%', borderRadius: 100, padding: 10, alignItems: 'center', justifyContent: 'center', backgroundImage: 'linear-gradient(to right, #d11450, #ef4123)', paddingLeft: 20, paddingRight: 20, fontFamily: 'Brown-Bold', cursor: 'pointer' }}>
                                Start Subscription
                        </button>
                        </div>
                        <div style={{ height: 25, flexWrap: "wrap", paddingBottom: 20, backgroundColor: '#fff', margin: 'auto', color: 'rgba(0,0,0,0.54)', width: '100%', textAlign: 'center' }}>
                            {"Cancel anytime after " + visibilityBoostData.data.cancellation_policy.days + " days"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}