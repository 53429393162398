export const START_FETCHING_PAYMENT_DATA = "START_FETCHING_PAYMENT_DATA";
export const ERROR_FETCHING_PAYMENT_DATA = "ERROR_FETCHING_PAYMENT_DATA";
export const SAVE_PAYMENT_DATA = "SAVE_PAYMENT_DATA";

export const START_FETCHING_PAYMENT_CALLBACK = "START_FETCHING_PAYMENT_CALLBACK";
export const ERROR_FETCHING_PAYMENT_CALLBACK = "ERROR_FETCHING_PAYMENT_CALLBACK";
export const SAVE_PAYMENT_CALLBACK = "SAVE_PAYMENT_CALLBACK";

export const START_SCREEN_METADATA = "START_SCREEN_METADATA";
export const ERROR_SCREEN_METADATA = "ERROR_SCREEN_METADATA";
export const SAVE_SCREEN_METADATA = "SAVE_SCREEN_METADATA";

export const START_FETCHING_GATEWAY_MODE_DATA = 'START_FETCHING_GATEWAY_MODE_DATA';
export const ERROR_FETCHING_GATEWAY_MODE_DATA = 'ERROR_FETCHING_GATEWAY_MODE_DATA';
export const SAVE_GATEWAY_MODE_DATA = 'SAVE_GATEWAY_MODE_DATA';
export const FETCH_GATEWAY_MODE_DATA = 'FETCH_GATEWAY_MODE_DATA';
