import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi'

export function saveWhatsappOptin(payload) {
    return {
        type: actionTypes.SAVE_WHATSAPP_OPTIN,
        payload: payload
    }
}

export function startWhatsappOptin() {
    return {
        type: actionTypes.START_WHATSAPP_OPTIN,
    }
}

export function errorWhatsappOptin(error) {
    return {
        type: actionTypes.ERROR_WHATSAPP_OPTIN,
        payload: error
    }
}

export function whatsappOptin(params) {
    const headers = {
        access_token: "access_token",
        locale: "en"
    }
    return (dispatch) => {
        dispatch(startWhatsappOptin());
        RESTApi.POST('/owner_ms/api/v1/users/whatsapp/optin/crm', params, null, () => {
            dispatch(saveWhatsappOptin({ success: true }))
        }, (error) => {
            dispatch(errorWhatsappOptin(error))
        }, headers)
    }
}