import React from 'react';
import './style.css';
import * as images from '../../../../assets/images/group-6@2x.png';
import Spinner from '../../../../components/Spinner';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import * as Actions from '../../actions';
import ReactGA from 'react-ga';
import gaConstants from '../../../../constants/gaConstants';

class UploadBox extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fileResponse: null,
        };
        this.onUploadPress = this.onUploadPress.bind(this);
        this.uploadDocument = this.uploadDocument.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        const { uploadDocumentData, type, index } = this.props;
        const key = (type === "PDC") ? (type.toLowerCase()+(index+1)) : (index===0?"front":"back");
        if (uploadDocumentData[key] && uploadDocumentData[key] !== prevProps.uploadDocumentData[key] && uploadDocumentData[key].error) {
            this.setState({
                fileResponse: null,
            });
        }
    }
     
    onUploadPress(response) {
        if (response.target.files && response.target.files[0]) {
            if (process.env.NODE_ENV === 'development')
                console.log('onUploadPressResponse::::',response.target.files);
            let formData = new FormData();
            formData.append('file', response.target.files[0]);
            // formData.append('name', 'some value user types');
            // formData.append('description', 'some value user types');
            // formData.append('file',
            //     {
            //         uri: URL.createObjectURL(response.target.files[0]),
            //         name: 'pdc1.jpeg',
            //         type: 'image/*'
            //     });
            // for (var pair of formData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }
            this.setState({ fileResponse: URL.createObjectURL(response.target.files[0]) });
            this.uploadDocument(formData)
        }

    }
    uploadDocument(formData) {
        const { type, index, hotelId } = this.props;
        const params = {
            hotel_id: hotelId,
            type: type,
            key: (type === "PDC") ? (type.toLowerCase()+(index+1)) : (index===0?"front":"back")
        };
        this.props.actions.uploadDocument(params, formData);
        ReactGA.event({
            category: gaConstants.categories.INSTANT_ADVANCE,
            action: gaConstants.actions.instant_advance.UPLOAD_DOCUMENT
        });
    }
    removeDocument() {
        const { type, index, hotelId } = this.props;
        const params = {
            hotel_id: hotelId,
            type: type,
            key: (type === "PDC") ? (type.toLowerCase()+(index+1)) : (index===0?"front":"back")
        };
        this.setState({ fileResponse: null });
        this.props.actions.removeDocument(params);
        ReactGA.event({
            category: gaConstants.categories.INSTANT_ADVANCE,
            action: gaConstants.actions.instant_advance.REMOVE_DOCUMENT
        });
    }
    render() {
        const { url, type, index, uploadDocumentData, data } = this.props;
        const { fileResponse } = this.state;
        const key = (type === "PDC") ? (type.toLowerCase()+(index+1)) : (index===0?"front":"back");
        return (
            type === "PDC" ?
            <div style={{ height: 184, width: 140, display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', padding: 10, paddingBottom: 10,  borderColor: '#f2f2f2', borderRadius: 4, borderWidth: 2, borderStyle: 'solid',  margin: 'auto', marginTop:10, backgroundColor: '#fff', }}>
                {/* <div style={{ height: 184, width: 140, backgroundColor: '#fff', padding: 10, paddingBottom: 10, margin:10, borderColor: '#f2f2f2', borderRadius: 4, borderWidth: 2, borderStyle: 'solid' }}> */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140, margin: 'auto', width: 140, backgroundColor: '#f8f8f8', }}>
                        {url || fileResponse ?
                            <div onClick={() => this.removeDocument()} style={{ position: "relative", display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', height: 140, margin: 'auto', width: 140, backgroundColor: '#f8f8f8' }}>
                                <div style={{ position: "absolute", zIndex: 1, height: '100%', width: '100%' }}>
                                    <img alt="" id={"cheque"+(index+1)} style={{ height: '100%', width: '100%', display: 'flex', position: "absolute", objectFit: 'contain' }} src={fileResponse ? fileResponse : url} />
                                </div>
                                <div style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', zIndex: 2, color: '#000', opacity: 0.5, borderRadius: 10 }}>
                                    <img alt="" src={images} style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', zIndex: 2, transform: 'rotate(45deg)', color: '#000', opacity: 0.5, backgroundColor: '#000', borderRadius: 200 }} />
                                </div>
                            </div>
                            :
                            uploadDocumentData[key] && uploadDocumentData[key].isFetching ?
                                <Spinner />
                                :
                                <div style={{ position: "relative", display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', height: 140, margin: 'auto', width: 140, backgroundColor: '#f8f8f8', borderColor: '#e6e6e6', borderRadius: 4, borderWidth: 2,borderStyle: 'dashed' }}>
                                    <div style={{ position: "absolute", zIndex: 1, }}>
                                        <img alt="" src={images} style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} />
                                        <h3 style={{ fontSize: 12, color: 'rgba(0,0,0,0.3)', fontFamily: 'Brown-Bold', textAlign: 'center' }}>Upload Signed & Filled Cheque {index + 1}</h3>
                                    </div>
                                    <input onChange={(e) => { this.onUploadPress(e) }} id={"imgInp"+(index+1)} type="file" accept="image/*" style={{ position: "absolute", top: 0, height: 140, width: 140, textAlign: "right", opacity: 0, zIndex: 2, MozOpacity: 0, filter: 'alpha(opacity:0)' }} />
                                </div>
                        }
                    </div>
                    <div>
                        <div style={{ alignContent: 'left', fontSize: 12, marginTop: '10px' }}>
                            {data.amount ? "Fill Amount: " + data.amount : ""}
                        </div>
                        <div style={{ alignContent: 'left', fontSize: 10, color: 'rgba(0,0,0,0.54)', fontFamily: 'Brown-Regular' }}>
                            {"Fill Date: 1 " + (data.month?data.month.substring(0,3):"") + ", " + (data.year?data.year:"")}
                        </div>
                    </div>
                </div>
                :
                <div style={{ display: 'flex',  justifyContent: 'center', alignItems: 'center', height: 140, margin: 'auto', width: 140, backgroundColor: '#f8f8f8', }}>
                   { fileResponse || url  ?
                    <div onClick={() => this.removeDocument()} style={{ position: "relative", display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', height: 140, margin: 'auto', width: 140, backgroundColor: '#f8f8f8',  }}>
                                <div style={{ position: "absolute", zIndex: 1, height: '100%', width: '100%' }}>
                                    <img alt="" style={{ height: '100%', width: '100%', display: 'flex', position: "absolute", objectFit: 'contain' }} src={fileResponse ? fileResponse : url} />
                                </div>
                                <div style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', zIndex: 2, color: '#000', opacity: 0.5, borderRadius: 10 }}>
                                    <img alt="" src={images} style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', zIndex: 2, transform: 'rotate(45deg)', color: '#000', opacity: 0.5, backgroundColor: '#000', borderRadius: 200 }} />
                                </div>
                    </div>
                    :<div style={{ position: "relative", display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', height: 140, margin: 'auto', width: 140, backgroundColor: '#f8f8f8', borderColor: '#e6e6e6', borderRadius: 4, borderWidth: 2,borderStyle: 'dashed' }}>
                        <div style={{ position: "absolute", zIndex: 1, }}>
                            <img alt="" src={images} style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} />
                            <h3 style={{ fontSize: 12, color: 'rgba(0,0,0,0.3)', fontFamily: 'Brown-Bold', textAlign: 'center' }}>
                                {key==="front" ? "Front" : "Back"}
                            </h3>
                        </div>
                        <input onClick={(e) => { this.onUploadPress(e) }} id="imgInp" type="file" accept="image/*" style={{ position: "absolute", top: 0, height: 140, width: 140, textAlign: "right", opacity: 0, zIndex: 2, MozOpacity: 0, filter: 'alpha(opacity:0)' }} />
                    </div>
                }
                </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
};
const mapStateToProps = (state, props) => {
    return {
        uploadDocumentData: state.instantAdvancesReducer.uploadDocument[props.type],
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadBox);
