import React from 'react';
import './style.css';
import Spinner from '../../components/Spinner';
import TryAgainLater from '../../components/TryAgainLater';
import ErrorToast from '../../components/ErrorToast';
import * as images from '../../assets/images/boost-icon.png';
import * as checkmark from '../../assets/images/checkmark.png';
import ThanksPage from './Components/ThanksPage';

export default class PublicPageReportRender extends React.PureComponent {
    render() {
        console.log('render', this.props)
        const { reportData, subscribeReport, errorToast, reportSubscriptionData, queryParams } = this.props;
        return (
            <div className="Container" style={{ overflowX: 'hidden' }}>
                {reportData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    :
                    Object.keys(reportData.data).length && queryParams && queryParams.source ?
                        <ReportSubscribeConfirm reportData={reportData} subscribeReport={subscribeReport} />
                        :
                        <TryAgainLater />
                }
                {reportSubscriptionData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    : null
                }
                {reportSubscriptionData.data && reportSubscriptionData.data.message ?
                    <div className="SpinnerContainer" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
                        <ThanksPage />
                    </div>
                    : null}
                <ErrorToast error={errorToast} />
            </div>
        );
    }
}

class ReportSubscribeConfirm extends React.PureComponent {
    render() {
        const { reportData, subscribeReport } = this.props;
        return (
            <div className="PitchDataContainer" style={{ backgroundColor: '#fff', maxWidth: 500, justifyContent: 'flex-start', alignItems: 'center', fontFamily: 'Brown-Regular', position: 'relative' }}>
                <div style={{ textAlign: 'center', width: '100%', border: '2px solid grey' }}>
                    <div style={{ padding: 25, backgroundColor: '#fff', borderRadius: '6px' }}>
                        <img src={images} alt="" />
                    </div>
                    <div style={{ margin: 'auto', flexDirection: "column", color: '#000', textAlign: 'center', padding: 10, lineHeight: 1.3, fontSize: 16, background: 'white' }}>
                        <h2 style={{ fontFamily: 'Brown-Bold' }}>Advanced Reports</h2>
                        {" Advanced Reports @ " + reportData.data.revenue_percentage + "% revenue/day will be unlocked for your hotel"}
                    </div>

                    <div style={{ padding: '15px', backgroundColor: '#fff', }}>
                        <div style={{ background: '#F3F5F7', padding: '15px', textAlign: 'left' }}>
                            How many of your guests are Repeat Guests
                           <div style={{ display: 'flex', marginTop: '15px', textAlign: 'center' }}>
                                <div style={{ width: ' 50%', color: 'white', background: '#6F93E5', padding: ' 10px 15px', borderTopLeftRadius: ' 8px', borderBottomLeftRadius: '8px' }}>X%</div>
                                <div style={{ width: ' 50%', color: 'white', background: '#91BE93', padding: ' 10px 15px', borderTopRightRadius: ' 8px', borderBottomRightRadius: '8px' }}>X%</div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '15px',fontSize:14}}>
                                <div style={{ width: ' 50%' }}>Repeat User</div>
                                <div style={{ width: ' 50%' }}>Firts time user</div>
                            </div>

                        </div>
                        <div style={{ background: '#F3F5F7', padding: '15px', marginTop: 25, textAlign: 'left' }}>
                            Split of bookings across price brackets
                           <div style={{ display: 'flex', marginTop: '15px', textAlign: 'center'}}>
                                <div style={{ width: '25%', color: 'white', background: '#6F93E5', padding: ' 10px 15px', borderTopLeftRadius: ' 8px', borderBottomLeftRadius: '8px' }}>X%</div>
                                <div style={{ width: '25%', color: 'white', background: '#91BE93', padding: ' 10px 15px' }}>X%</div>
                                <div style={{ width: '25%', color: 'white', background: '#E5B66A', padding: ' 10px 15px' }}>X%</div>
                                <div style={{ width: '25%', color: 'white', background: '#AEE5E0', padding: ' 10px 15px', borderTopRightRadius: ' 8px', borderBottomRightRadius: '8px' }}>X%</div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '15px',fontSize:14 }}>
                                <div style={{ width: '25%' }}>Rs 0</div>
                                <div style={{ width: '25%' }}>Rs 1000</div>
                                <div style={{ width: '25%' }}>Rs 1500</div>
                                <div style={{ width: '25%' }}>Rs 2000</div>
                            </div>

                        </div>
                    </div>
                    <div style={{ backgroundColor: '#fff', padding: '15px', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <div style={{ backgroundColor: '#fff', maxWidth: 350, width: '100%', borderColor: 'rgba(0,0,0,0.06)', borderStyle: 'solid', borderWidth: 1, borderRadius: 4, textAlign: 'center', margin: 'auto', marginBottom: 20 }}>
                            <div style={{ textAlign: 'left', padding: 10, display: 'flex', alignItems: 'center' }}><img src={checkmark} style={{ maxWidth: 15, paddingRight: 10 }} /> <div>Make data-driven decisions</div></div>
                            <div style={{ textAlign: 'left', padding: 10, display: 'flex', alignItems: 'center' }}><img src={checkmark} style={{ maxWidth: 15, paddingRight: 10 }} /> <div>Added information on Daily ARR</div></div>
                            <div style={{ textAlign: 'left', padding: 10, display: 'flex', alignItems: 'center' }}><img src={checkmark} style={{ maxWidth: 15, paddingRight: 10 }} /><div>Grow your bussiness with insights</div></div>
                        </div>
                        <div style={{ backgroundColor: '#fff', maxWidth: 350, width: '100%', borderColor: 'rgba(0,0,0,0.06)', borderStyle: 'solid', borderWidth: 1, borderRadius: 4, textAlign: 'center', margin: 'auto', marginBottom: 20 }}>
                            <div style={{ textAlign: 'left', padding: 10, }}><b>1000+</b> Hotels have opted in this.</div>
                        </div>

                        <div style={{ height: 50, width: '100%', margin: 'auto', backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: 10 }}>
                            <button onClick={() => subscribeReport()} style={{ color: '#fff', fontSize: 15, fontWeight: 10, width: '100%', maxWidth: 350, height: '100%', borderRadius: 100, padding: 10, alignItems: 'center', justifyContent: 'center', backgroundImage: 'linear-gradient(to right, #d11450, #ef4123)', paddingLeft: 20, paddingRight: 20, fontFamily: 'Brown-Bold', cursor: 'pointer' }}>
                                Start Subscription
                        </button>
                        </div>

                    </div>
                    <div style={{ flexWrap: "wrap", padding: '20px 0', backgroundColor: '#f5f6f6', margin: 'auto', color: 'rgba(0,0,0,0.54)', width: '100%', textAlign: 'center', left: 0, right: 0 }}>
                        {"Cancel anytime after " + reportData.data.cancellation_policy.days + " days"}
                    </div>
                </div>
            </div>
        )
    }
}