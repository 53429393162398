export const PAY_LABELS = {
  warnMsg: 'To retry the transaction, please click on the retry button below',
  warnDis: 'Do not press the browser’s Back or refresh button',
  warnBtn: 'Retry Payment',
  listErr: 'We are experiencing some issues, please try again after a few minutes'
}

export const GATEWAY_LIST = {
  xendit: 'XENDIT'
}
