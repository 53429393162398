/*
@@@@@@@@@@@@ Usage 
  const user = {
    id: 101,
    email: 'jack@dev.com',
    personalInfo: {
        name: 'Jack',
        address: {
            line1: 'westwish st',
            line2: 'washmasher',
            city: 'wallas',
            state: 'WX'
        }
    }
  }
  getNestedValue(user, 'personalInfo.name');
*/

const isExist = (variable) => variable !== undefined && variable !== null;

const getType = (type) => {
  switch (type) {
    case "array":
      return [];
    case "object":
      return {};
    case "string":
      return "";
    default:
      return undefined;
  }
};

export const getNestedValue = (obj, nestedProp, type) => {
  if (!isExist(obj)) return getType(type);

  try {
    nestedProp.split(/[\[\]\.]+/);
  } catch (e) {
    return undefined;
  }

  const props = nestedProp.split(/[\[\]\.]+/);

  let prev = null;
  let curr = obj;
  for (let i = 0; i < props.length; i++) {
    let prop = props[i];
    if (prop.length === 0) continue;
    prev = curr;
    curr = curr[prop];
    if (!isExist(curr)) {
      return getType(type);
    }
  }
  if (curr === undefined) {
    return getType(type);
  }
  return curr;
};
