import * as actionTypes from './actionTypes';
import { PLBState as initialState } from './state';

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SAVE_PLB:
            return {
                ...state,
                plbData: {
                    data: action.payload.data,
                    error: false,
                    isFetching: false
                }
            }

        case actionTypes.START_FETCHING_PLB:
            return {
                ...state,
                plbData: {
                    ...state.plbData,
                    isFetching: true,
                    error: false
                }
            }

        case actionTypes.ERROR_FETCHING_PLB:
            return {
                ...state,
                plbData: {
                    ...state.plbData,
                    isFetching: false,
                    error: action.payload
                    //error: false,
                    //data: plbData.data
                }
            }

        case actionTypes.START_SUBSCRIBING_PLB:
            return {
                ...state,
                plbSubscription: {
                    ...state.plbSubscription,
                    isFetching: true,
                    error: false,
                }
            }

        case actionTypes.ERROR_SUBSCRIBING_PLB:
            return {
                ...state,
                plbSubscription: {
                    ...state.plbSubscription,
                    isFetching: false,
                    error: action.payload,
                },
            }

        case actionTypes.SAVE_SUBSCRIBED_PLB:
            return {
                ...state,
                plbSubscription: {
                    data: action.payload,
                    error: false,
                    isFetching: false,
                }
            }

        default: return state

    }
}

export default reducer