import React from 'react';
import './style.css';
import Spinner from '../../components/Spinner';
import TryAgainLater from '../../components/TryAgainLater';
import ErrorToast from '../../components/ErrorToast';
import * as images from '../../assets/images/boost-icon.png';
import * as hotel from '../../assets/images/hotel.jpg';
import * as checkmark from '../../assets/images/checkmark.png';
import ThanksPage from './Components/ThanksPage';

export default class PublicPageWebsiteRender extends React.PureComponent {
    render() {
        console.log('render', this.props)
        const { websiteData, subscribeWebsite, errorToast, websiteSubscriptionData, queryParams } = this.props;
        return (
            <div className="Container" style={{ overflowX: 'hidden' }}>
                {websiteData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    :
                    Object.keys(websiteData.data).length && queryParams && queryParams.source ?
                        <WebsiteSubscribeConfirm websiteData={websiteData} subscribeWebsite={subscribeWebsite} />
                        :
                        <TryAgainLater />
                }
                {websiteSubscriptionData.isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    : null
                }
                {websiteSubscriptionData.data && websiteSubscriptionData.data.message ?
                    <div className="SpinnerContainer" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
                        <ThanksPage />
                    </div>
                    : null}
                <ErrorToast error={errorToast} />
            </div>
        );
    }
}

class WebsiteSubscribeConfirm extends React.PureComponent {
    render() {
        const { websiteData, subscribeWebsite } = this.props;
        return (
            <div className="PitchDataContainer" style={{ backgroundColor: '#fff', maxWidth: 500, justifyContent: 'flex-start', alignItems: 'center', fontFamily: 'Brown-Regular', position: 'relative' }}>
                <div style={{ textAlign: 'center', width: '100%', border: '2px solid grey' }}>
                    <div style={{ padding: 25, backgroundColor: '#fff', borderRadius: '6px' }}>
                        <img src={images} alt="" />
                    </div>
                    <div style={{ margin: 'auto', flexDirection: "column", color: '#000', textAlign: 'center', padding: 10, lineHeight: 1.3, fontSize: 16 }}>
                        <h2 style={{ fontFamily: 'Brown-Bold' }}>Your Hotel Website</h2>
                        {" Hotel Website @ " + websiteData.data.revenue_percentage + "% revenue/day will be live for your hotel"}
                    </div>

                    <div style={{ padding: '15px', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', }}>
                        <img src={hotel} alt="" style={{ maxWidth: 350, width: '100%' }} />
                    </div>
                    <div style={{ backgroundColor: '#fff', padding: '15px', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <div style={{ backgroundColor: '#fff', maxWidth: 350, width: '100%', borderColor: 'rgba(0,0,0,0.06)', borderStyle: 'solid', borderWidth: 1, borderRadius: 4, textAlign: 'center', margin: 'auto', marginBottom: 20 }}>
                            <div style={{ textAlign: 'left', padding: 10, display: 'flex', alignItems: 'center' }}><img src={checkmark} style={{ maxWidth: 15, paddingRight: 10 }} /> <div>Build your hotel's brand on the internet</div></div>
                            <div style={{ textAlign: 'left', padding: 10, display: 'flex', alignItems: 'center' }}><img src={checkmark} style={{ maxWidth: 15, paddingRight: 10 }} /> <div>Share your website with friends & family</div></div>
                            <div style={{ textAlign: 'left', padding: 10, display: 'flex', alignItems: 'center' }}><img src={checkmark} style={{ maxWidth: 15, paddingRight: 10 }} /><div> Get more online customers</div></div>
                        </div>
                        <div style={{ backgroundColor: '#fff', maxWidth: 350, width: '100%', borderColor: 'rgba(0,0,0,0.06)', borderStyle: 'solid', borderWidth: 1, borderRadius: 4, textAlign: 'center', margin: 'auto', marginBottom: 20 }}>
                            <div style={{ textAlign: 'left', padding: 10, }}><b>1000+</b> Hotels have opted in this.</div>
                        </div>

                        <div style={{ height: 50, width: '100%', margin: 'auto', backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: 10 }}>
                            <button onClick={() => subscribeWebsite()} style={{ color: '#fff', fontSize: 15, fontWeight: 10, width: '100%', maxWidth: 350, height: '100%', borderRadius: 100, padding: 10, alignItems: 'center', justifyContent: 'center', backgroundImage: 'linear-gradient(to right, #d11450, #ef4123)', paddingLeft: 20, paddingRight: 20, fontFamily: 'Brown-Bold', cursor: 'pointer' }}>
                                Start Subscription
                        </button>
                        </div>

                    </div>
                    <div style={{ flexWrap: "wrap", padding: '20px 0', backgroundColor: '#f5f6f6', margin: 'auto', color: 'rgba(0,0,0,0.54)', width: '100%', textAlign: 'center', left: 0, right: 0 }}>
                        {"Cancel anytime after " + websiteData.data.cancellation_policy.days + " days"}
                    </div>
                </div>
            </div>
        )
    }
}