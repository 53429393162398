import React from 'react';
import { FaTimes } from 'react-icons/fa';

export default class TryAgainLater extends React.PureComponent {
    render() {
        return (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', padding: '20vh 20px 0' }}>
                <FaTimes style={{ height: 80, width: 80, color: '#d11450' }} />
                <h1 style={{ color: 'rgba(0,0,0,0.87)', textAlign: 'center', padding: '0 15px' }} >We're sorry, but something went wrong.</h1>
                <h2 style={{ color: 'rgba(0,0,0,0.66)', textAlign: 'center' }} >The issue has been logged for investigation. Please try again later.</h2>
            </div>
        );
    }
}