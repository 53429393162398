import * as actionTypes from './actionTypes';
import { SmartPricingState as initialState } from './state';

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SAVE_SMART_PRICING:
            return {
                ...state,
                smartPricingData: {
                    data: action.payload.data,
                    error: false,
                    isFetching: false
                }
            }

        case actionTypes.START_FETCHING_SMART_PRICING:
            return {
                ...state,
                smartPricingData: {
                    ...state.smartPricingData,
                    isFetching: true,
                    error: false
                }
            }

        case actionTypes.ERROR_FETCHING_SMART_PRICING:
            return {
                ...state,
                smartPricingData: {
                    ...state.smartPricingData,
                    isFetching: false,
                    error: action.payload
                    //error: false,
                    //data: smartPricingData.data
                }
            }

        case actionTypes.START_SUBSCRIBING_SMART_PRICING:
            return {
                ...state,
                smartPricingSubscription: {
                    ...state.smartPricingSubscription,
                    isFetching: true,
                    error: false,
                }
            }

        case actionTypes.ERROR_SUBSCRIBING_SMART_PRICING:
            return {
                ...state,
                smartPricingSubscription: {
                    ...state.smartPricingSubscription,
                    isFetching: false,
                    error: action.payload,
                },
            }

        case actionTypes.SAVE_SUBSCRIBED_SMART_PRICING:
            return {
                ...state,
                smartPricingSubscription: {
                    data: action.payload,
                    error: false,
                    isFetching: false,
                }
            }

        default: return state

    }
}

export default reducer