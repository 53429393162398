import React, { Component } from "react";
import "./style.scss";
import image from "../../assets/images/total-revenue.svg";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";
import { getNestedValue } from "../../utils/objectNesting";
import ReactGA from "react-ga";
import gaConstants from "../../constants/gaConstants";
import moment from "moment";
import {
  Select,
  Row,
  Col,
  DatePicker,
  Input,
  Upload,
  Checkbox,
  Icon,
  Button,
} from "antd";
import axios from "axios";
import { API_HOST } from "../../constants/apiConstants";
import Base64 from "../../utils/base64";
import ErrorBoxComponent from "../common/errorBoxComponent";
import { locale } from "../../constants/apiConstants";
import { epochToDate } from "../../utils/DateUtils";
import get from "lodash/get";

const { Option } = Select;

class OfflinePaymentRender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_mode: undefined,
      date: null,
      transaction_id: "",
      transaction_proof_urls: [],
      checked: false,
      date_display: "Date of Payment",
      transaction_id_display: "Unique Transaction ID",
      url_display: "Add Photo Proofs",
      amount_editing: false,
      payment_amount: "",
      currency: "INR",
      redirect: false,
      error: "",
      fileList: [],
      hotel_id: "",
      rechargeType: ''
    };
  }

  componentDidMount() {
    const { fetchPaymentModes } = this.props.actions;
    if (this.props.paymentData != "") {
      fetchPaymentModes({ payment_data: this.props.paymentData });
      this.setPayment();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.paymentData !== prevProps.paymentData) {
      const { fetchPaymentModes } = this.props.actions;
      fetchPaymentModes({ payment_data: this.props.paymentData });
      this.setPayment();
    }
    if (this.props.recordPayment !== prevProps.recordPayment) {
      const data = getNestedValue(this.props, "recordPayment.data");
      const error = getNestedValue(this.props, "recordPayment.error");
      const isFetching = getNestedValue(this.props, "recordPayment.isFetching");
      let msg = "";
      if (typeof error === "object" && error.message) {
        msg = error.message;
      } else {
        msg = error;
      }
      if (isFetching === false && data !== undefined && data !== "" && Object.keys(data).length > 0) {
        this.setState({
          redirect: true,
        });
      } else if (msg !== "") {
        this.setState({ error: msg });
      }
    }
  }

  setPayment = () => {
    var decodedHashKey = Base64.atob(this.props.paymentData).split(":");
    this.setState({
      payment_amount: decodedHashKey[1],
      currency: decodedHashKey[5],
      hotel_id: decodedHashKey[4],
      rechargeType: decodedHashKey[2]
    });
  };

  handlePaymentMode = (val) => {
    const payment_modes = getNestedValue(
      this.props,
      "paymentModes.data.payment_modes",
      "array"
    );
    const selected = payment_modes.find((item) => item.payment_mode == val);
    const date_of_payment_label = getNestedValue(
      selected,
      "date_of_payment_label"
    );
    const transaction_number_label = getNestedValue(
      selected,
      "transaction_number_label"
    );
    const proof_label = getNestedValue(selected, "proof_label");
    this.setState({
      payment_mode: val,
      date_display: date_of_payment_label,
      transaction_id_display: transaction_number_label,
      url_display: proof_label,
    });
  };

  handleDateChange = (val) => {
    this.setState({ date: val });
  };

  handleTransIdChange = (e) => {
    this.setState({ transaction_id: e.target.value });
  };

  handleChecked = (e) => {
    this.setState({ checked: e.target.checked });
  };

  handleEdit = () => {
    this.setState({ amount_editing: !this.state.amount_editing });
  };

  handleAmountChange = (e) => {
    this.setState({ payment_amount: e.target.value });
  };

  checkSubmit = () => {
    const {
      payment_mode,
      date,
      transaction_id,
      transaction_proof_urls,
      checked,
      payment_amount,
    } = this.state;
    if (
      payment_mode == undefined ||
      date == null ||
      transaction_id == "" ||
      transaction_proof_urls.length == 0 ||
      checked == false ||
      payment_amount == ""
    ) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.state.payment_amount == 0) {
      return this.setState({
        error: get(
          this.props,
          "screenMetaData.data.screen_labels.oyo-secure-web.SECURE_WEB_OFFLINE_ZERO",
          "Paid Amount can't zero"
        )
      });
    }
    if (this.checkSubmit()) {
      const { fetchRecordPayment } = this.props.actions;
      let params = { payment_data: this.props.paymentData };
      let body = {
        amount: this.state.payment_amount,
        date: epochToDate(this.state.date, "YYYY-MM-DD"),
        transaction_id: this.state.transaction_id,
        payment_mode: this.state.payment_mode,
        transaction_proof_urls: this.state.transaction_proof_urls,
      };
      ReactGA.event({
        category: gaConstants.categories.SECURE,
        action: gaConstants.actions.secure.SUBMIT_PROOFS,
        label: this.state.hotel_id,
      });
      ReactGA.event({
        category: gaConstants.categories.SECURE,
        action: gaConstants.actions.secure.PAYMENT_MODE,
        label: this.state.payment_mode,
      });
      fetchRecordPayment(params, body);
    } else {
      this.setState({ error: "Please fill all the fields" });
    }
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      this.props.history.push(
        "/payment/submit?payment_data=" + this.props.paymentData
      );
    }
  };

  customRequest = (e) => {
    this.uploadFileToBucket(e);
  };

  uploadFileToBucket = async ({ onProgress, onSuccess, onError, file }) => {
    let headers = {
      Authorization: "secure_authorization",
      Accept: "application/json",
      locale: locale,
      "Content-Type": "application/json",
    };
    let params = {
      payment_data: this.props.paymentData,
    };
    try {
      const { data } = await axios({
        url: `${API_HOST}/owner_ms/api/v1/oyo-secure/upload-transaction-link`,
        method: "GET",
        params,
        headers,
      });
      let upload_url_data = getNestedValue(
        data,
        "file_upload_urls",
        "array"
      )[0];
      let { upload_url, file_url } = upload_url_data;
      let upload_headers = {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      };
      let onUploadProgress = (e) => {
        onProgress({ percent: (e.loaded / e.total) * 100 });
      };
      try {
        const { data: upload_data } = await axios({
          url: upload_url,
          method: "PUT",
          data: file,
          headers: upload_headers,
          onUploadProgress: onUploadProgress,
        });
        const newProofUrls = [...this.state.transaction_proof_urls, file_url];
        const newFileList = [
          ...this.state.fileList,
          { file: file, file_url: file_url },
        ];
        this.setState(
          { transaction_proof_urls: newProofUrls, fileList: newFileList },
          () => {
            onSuccess(null, file);
          }
        );
      } catch (e) {
        onError("File Upload Failed", file);
        return;
      }
    } catch (e) {
      onError("File Upload Failed", file);
      return;
    }
  };

  onRemove = (file) => {
    let { fileList, transaction_proof_urls } = this.state;
    let file_obj = fileList.find((item) => item.file.uid === file.uid);
    if (file_obj !== undefined) {
      let indexFileList = fileList.indexOf(file_obj);
      if (indexFileList > -1) {
        fileList.splice(indexFileList, 1);
      }
      let indexProofUrls = transaction_proof_urls.indexOf(file_obj.file_url);
      if (indexProofUrls > -1) {
        transaction_proof_urls.splice(indexProofUrls, 1);
      }
      this.setState({
        fileList: fileList,
        transaction_proof_urls: transaction_proof_urls,
      });
    }
  };

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  render() {
    const dateFormat = "DD-MMM-YYYY";
    const uploadProps = {
      customRequest: this.customRequest,
      listType: "picture",
      accept: ".png, .jpg, .jpeg",
      multiple: true,
      className: "upload-list-inline antd_modified",
      onRemove: this.onRemove,
    };
    const payment_modes = getNestedValue(
      this.props,
      "paymentModes.data.payment_modes",
      "array"
    );
    let screenLabels = getNestedValue(
      this.props,
      "screenMetaData.data.screen_labels.oyo-secure-web"
    );
    return (
      <div className="mainContainer">
        <div className="payment">
          <ErrorBoxComponent error={this.state.error} />
          <div className="payment__amountBox">
            <img src={image} className="payment--logo"></img>
            <div className="payment__inputBox">
              <span className="paid-amount">
                {getNestedValue(screenLabels, "SECURE_WEB_TITLE_PAID_AMOUNT") ||
                  "Paid Amount"}
              </span>
              <div className="amount_box">
                <span>{getCurrencySymbol(this.state.currency)}</span>
                <input
                  className={
                    "amount" + (this.state.amount_editing ? "__edit" : "")
                  }
                  disabled={!this.state.amount_editing}
                  type="number"
                  name="amount"
                  min="0"
                  onChange={this.handleAmountChange}
                  value={this.state.payment_amount}
                />
              </div>
            </div>
            {this.state.rechargeType != 'INITIAL_RECHARGE' && (
              <div className="payment__editButton" onClick={this.handleEdit}>
                {getNestedValue(screenLabels, "SECURE_WEB_LABEL_EDIT_AMOUNT") ||
                  "Edit"}
              </div>
            )}
          </div>
          <div className="payment__detailBox">
            <span className="detail_headline">
              {getNestedValue(
                screenLabels,
                "SECURE_WEB_TITLE_OFFLINE_PAYMENT_DETAILS"
              ) || "Offline Payment Details"}
            </span>
            <p>
              {getNestedValue(
                screenLabels,
                "SECURE_WEB_TITLE_OFFLINE_PAYMENT_PAGE_DESCRIPTION"
              ) ||
                "Please select the mode of offline payment & upload the proofs as mentioned below"}
            </p>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Select
                  style={{ width: "100%" }}
                  placeholder={
                    getNestedValue(
                      screenLabels,
                      "SECURE_WEB_LABEL_PAYMENT_MODE"
                    ) || "Payment Mode"
                  }
                  value={this.state.payment_mode}
                  onChange={this.handlePaymentMode}
                  className="antd_modified"
                  dropdownClassName="antd_modified"
                  allowClear
                >
                  {payment_modes.map((item) => (
                    <Option key={item.payment_mode} value={item.payment_mode}>
                      {item.payment_mode_display_name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} lg={12}>
                <DatePicker
                  style={{ width: "100%" }}
                  format={dateFormat}
                  value={this.state.date}
                  onChange={this.handleDateChange}
                  disabledDate={this.disabledDate}
                  className="antd_modified"
                  dropdownClassName="antd_modified"
                  placeholder={this.state.date_display || "Date of Payment"}
                />
              </Col>
              <Col span={24}>
                <Input
                  placeholder={
                    this.state.transaction_id_display || "Unique Transaction ID"
                  }
                  value={this.state.transaction_id}
                  onChange={this.handleTransIdChange}
                  className="antd_modified"
                  allowClear
                />
              </Col>
              <Col span={24}>
                <div className="payment__uploadBox">
                  <Upload {...uploadProps}>
                    <span>{this.state.url_display || "Add Photo Proofs"}</span>
                    <Icon type="paper-clip" />
                  </Upload>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} className="antd_modified">
                <Checkbox
                  className="check_box"
                  checked={this.state.checked}
                  onChange={this.handleChecked}
                >
                  {getNestedValue(
                    screenLabels,
                    "SECURE_WEB_MESSAGE_SUBMIT_CONFIRMATION"
                  ) ||
                    "By Submitting your declare that the details provided are authentic"}
                </Checkbox>
              </Col>
              <Col span={24} className="antd_modified">
                <Button
                  className="payment__submitButton"
                  type="danger"
                  disabled={!this.state.checked}
                  block
                  onClick={this.onSubmit}
                >
                  {getNestedValue(
                    screenLabels,
                    "SECURE_WEB_BUTTON_SUBMIT_PROOFS"
                  ) || "Submit Proofs"}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        {this.renderRedirect()}
      </div>
    );
  }
}

export default OfflinePaymentRender;
