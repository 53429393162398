import React,{Component} from 'react';
import './style.css';

export default class NotFoundScreen extends Component{
	constructor(props){
		super(props);
		this.handleBtnClick = this.handleBtnClick.bind(this);
	}
	handleBtnClick(){
		this.props.actions.getWeatherData();
	}
	render(){
		return(
			<div className='MainContainer'>
				<img src={process.env.PUBLIC_URL + '/assets/images/not_found.svg'} alt="" className="NotFoundImage" />
				<div className="NotFoundText">It looks like we don’t have the page you’re looking for. </div>
			</div>
		)
	}
}
