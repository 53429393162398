import Base64 from './base64';
import { getCurrencySymbol } from './getCurrencySymbol';

export const getPaymentData = (key, pageMode) => {
  const paymentobj = {
    paymentValue: 0,
    recharge_type: '',
    hotel_id: '',
    paymentType: '',
    currencySymbol: undefined
  }
  if (!key) {
    return paymentobj;
  }

  const decodedHashKey = Base64.atob(key).split(':');

  if (!Array.isArray(decodedHashKey)) {
    return paymentobj;
  }

  if (pageMode == 'RECON_INDIA') {
    paymentobj['paymentValue'] = decodedHashKey[0];
    paymentobj['hotel_id'] = decodedHashKey[2];
    paymentobj['currencySymbol'] = getCurrencySymbol('INR');
  } else {
    paymentobj['paymentValue'] = decodedHashKey[1];
    paymentobj['recharge_type'] = decodedHashKey[2];
    paymentobj['hotel_id'] = decodedHashKey[4];
    paymentobj['paymentType'] = decodedHashKey[6];
    paymentobj['currencySymbol'] = getCurrencySymbol(decodedHashKey[5]);
  }

  return paymentobj;
}

