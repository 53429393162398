import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi';
import { HMSBFF_HOST, locale } from '../../constants/apiConstants';
import ReactGA from 'react-ga';
import gaConstants from '../../constants/gaConstants';
import { getNestedValue } from '../../utils/objectNesting';
import get from 'lodash/get';

const urlOrigin = window.location.origin || 'https://cooyo.oyorooms.com';
const redirectUrl = `${urlOrigin}/#/payment/callback`;

export function savePaymentData(payload) {
  return {
    type: actionTypes.SAVE_PAYMENT_DATA,
    payload: payload
  };
}

export function startFetchingPaymentData() {
  return {
    type: actionTypes.START_FETCHING_PAYMENT_DATA
  };
}

export function errorFetchingPaymentData(error) {
  return {
    type: actionTypes.ERROR_FETCHING_PAYMENT_DATA,
    payload: error
  };
}

export function savePaymentCallback(payload) {
  return {
    type: actionTypes.SAVE_PAYMENT_CALLBACK,
    payload: payload
  };
}

export function startFetchingPaymentCallback() {
  return {
    type: actionTypes.START_FETCHING_PAYMENT_CALLBACK
  };
}

export function errorFetchingPaymentCallback(error) {
  return {
    type: actionTypes.ERROR_FETCHING_PAYMENT_CALLBACK,
    payload: error
  };
}

export function fetchPaymentCallback(body) {
  return dispatch => {
    dispatch(startFetchingPaymentCallback());
    let headers = {
      Authorization: 'secure_authorization'
    };

    RESTApi.POST(
      `${HMSBFF_HOST}/owner_ms/api/v1/payments/verify`,
      {
        page_mode: localStorage.getItem('pageMode')
      },
      body,
      data => {
        const status = getNestedValue(data, 'data.status', 'string').toUpperCase();
        if (status === 'SUCCESS' || status === 'PENDING') {
          ReactGA.event({
            category: gaConstants.categories.SECURE,
            action: gaConstants.actions.secure.VERIFY_API_SUCCESS,
            txnid: body['txnid']
          });
        } else {
          ReactGA.event({
            category: gaConstants.categories.SECURE,
            action: gaConstants.actions.secure.VERIFY_API_FAILED,
            txnid: body['txnid']
          });
        }
        dispatch(savePaymentCallback(data));
      },
      error => {
        ReactGA.event({
          category: gaConstants.categories.SECURE,
          action: gaConstants.actions.secure.VERIFY_API_FAILED,
          txnid: body['txnid']
        });
        dispatch(errorFetchingPaymentCallback(error));
      },
      headers
    );
  };
}

export function fetchAddMoneyData(body, toast, setErrorToast) {
  return dispatch => {
    dispatch(startFetchingPaymentData());
    let headers = {
      Authorization: 'secure_authorization'
    };

    RESTApi.POST(
      `${HMSBFF_HOST}/owner_ms/api/v1/payments/initiate`,
      null,
      body,
      data => {
        if (get(body, 'offer_id') && !get(data, 'data.create_subscription_response.success', false)){
          setErrorToast('Offer selected has expired, please go back and choose another offer')
          setTimeout(() => {
            if (data && data.data && data.data.payment_data) {
              data.data.payment_data = {
                ...data.data.payment_data,
                data: {
                  ...(data.data.payment_data.data && data.data.payment_data.data),
                  surl: redirectUrl,
                  furl: redirectUrl,
                },
                method: 'POST',
                payment_complete_url: redirectUrl,
                payment_fail_url: redirectUrl
              }
              dispatch(savePaymentData(data.data.payment_data));
            } else {
              dispatch(savePaymentData(data && data.data));
            }
          }, 5000);
        } else {
        if (data && data.data && data.data.payment_data) {
          data.data.payment_data.data.surl = redirectUrl;
          data.data.payment_data.data.furl = redirectUrl;
          data.data.payment_data.method = 'POST';
          data.data.payment_data.payment_complete_url = redirectUrl;
          data.data.payment_data.payment_fail_url = redirectUrl;
          dispatch(savePaymentData(data.data.payment_data));
        } else {
          dispatch(savePaymentData(data && data.data));
        }
        }
      },
      error => {
        dispatch(errorFetchingPaymentData(error));
      },
      headers
    );
  };
}

export function saveScreenMetadata(payload) {
  return {
    type: actionTypes.SAVE_SCREEN_METADATA,
    payload: payload
  };
}

export function startScreenMetadata() {
  return {
    type: actionTypes.START_SCREEN_METADATA
  };
}

export function errorScreenMetadata(error) {
  return {
    type: actionTypes.ERROR_SCREEN_METADATA,
    payload: error
  };
}

export function fetchScreenMetadata(params) {
  return dispatch => {
    dispatch(startScreenMetadata());
    let headers = {
      Authorization: 'secure_authorization',
      locale: locale
    };
    RESTApi.GET(
      `${HMSBFF_HOST}/owner_ms/api/v1/web/screen-metadata`,
      params,
      data => {
        if (data) {
          dispatch(saveScreenMetadata(data));
        }
      },
      error => {
        dispatch(errorScreenMetadata(error));
      },
      headers
    );
  };
}

/** List of wallet */
export function startFetchingGatewayModeData() {
  return {
    type: actionTypes.START_FETCHING_GATEWAY_MODE_DATA
  };
}

export function saveGatewayModeData(payload) {
  return {
    type: actionTypes.SAVE_GATEWAY_MODE_DATA,
    payload: payload
  };
}

export function errorFetchingGatewayModeData(error) {
  return {
    type: actionTypes.ERROR_FETCHING_GATEWAY_MODE_DATA,
    payload: error
  };
}

export function fetchGatewayModeData(params) {
  /** screen_name must be array */
  return dispatch => {
    dispatch(startFetchingGatewayModeData());
    const headers = {
      Authorization: 'secure_authorization',
      locale: locale
    };
    RESTApi.GET(
      `${HMSBFF_HOST}/owner_ms/api/v2/payments/gateways`,
      params,
      data => {
        dispatch(saveGatewayModeData(data));
      },
      error => {
        dispatch(errorFetchingGatewayModeData(error));
      },
      headers
    );
  };
}
