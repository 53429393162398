import _ from 'lodash';

const splitUrl = (url) => {
  return url.split('/').filter(item => item)
}

export const urlCompare = (url1, url2) => {
  if (url1 && url2) {
    return _.isEqual(splitUrl(url1), splitUrl(url2));
  }
  return false;
}
