export const OfflinePaymentState = {
  paymentModes: {
    data: {},
    isFetching: false,
    error: '',
  },
  recordPayment: {
    data: {},
    isFetching: false,
    error: '',
  },
};
