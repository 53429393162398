import * as actionTypes from './actionTypes';
import { InstantAdvancesState as initialState } from './state';

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SAVE_PITCH_DATA:
            return {
                ...state,
                pitchData: {
                    data: action.payload.data,
                    error: false,
                    isFetching: false
                }
            };

        case actionTypes.START_FETCHING_PITCH_DATA:
            return {
                ...state,
                pitchData: {
                    ...state.pitchData,
                    isFetching: true,
                    error: false
                }
            };

        case actionTypes.ERROR_FETCHING_PITCH_DATA:
            return {
                ...state,
                pitchData: {
                    ...state.pitchData,
                    isFetching: false,
                    error: action.payload
                }
            };

        default: return state;

    }
};

export default reducer;