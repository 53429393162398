import * as actionTypes from "./actionTypes";
import RESTApi from "../../api/RESTApi";
import { locale } from "../../constants/apiConstants";

export function savePaymentModes(payload) {
  return {
    type: actionTypes.SAVE_PAYMENT_MODES,
    payload: payload,
  };
}

export function startPaymentModes() {
  return {
    type: actionTypes.START_PAYMENT_MODES,
  };
}

export function errorPaymentModes(error) {
  return {
    type: actionTypes.ERROR_PAYMENT_MODES,
    payload: error,
  };
}

export function fetchPaymentModes(params) {
  return (dispatch) => {
    dispatch(startPaymentModes());
    let headers = {
      Authorization: "secure_authorization",
      locale: locale,
    };
    RESTApi.GET(
      "/owner_ms/api/v1/oyo-secure/offline-payment-modes",
      params,
      (data) => {
        if (data) {
          dispatch(savePaymentModes(data));
        }
      },
      (error) => {
        dispatch(errorPaymentModes(error));
      },
      headers
    );
  };
}

export function saveRecordPayment(payload) {
  return {
    type: actionTypes.SAVE_RECORD_PAYMENT,
    payload: payload,
  };
}

export function startRecordPayment() {
  return {
    type: actionTypes.START_RECORD_PAYMENT,
  };
}

export function errorRecordPayment(error) {
  return {
    type: actionTypes.ERROR_RECORD_PAYMENT,
    payload: error,
  };
}

export function fetchRecordPayment(params, body) {
  return (dispatch) => {
    dispatch(startRecordPayment());
    let headers = {
      Authorization: "secure_authorization",
      locale: locale,
    };
    RESTApi.POST(
      "/owner_ms/api/v1/oyo-secure/record-offline-payment",
      params,
      body,
      (data) => {
        if (data) {
          dispatch(saveRecordPayment(data));
        }
      },
      (error) => {
        dispatch(errorRecordPayment(error));
      },
      headers
    );
  };
}
