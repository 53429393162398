import * as actionTypes from './actionTypes';
import RESTApi from '../../api/RESTApi'

export function savePitchData(payload) {
    return {
        type: actionTypes.SAVE_PITCH_DATA,
        payload: payload
    }
}

export function startFetchingPitchData() {
    return {
        type: actionTypes.START_FETCHING_PITCH_DATA,
    }
}

export function errorFetchingPitchData(error) {
    return {
        type: actionTypes.ERROR_FETCHING_PITCH_DATA,
        payload: error
    }
}

export function fetchPitchData(params) {
    return (dispatch) => {
        dispatch(startFetchingPitchData());
        RESTApi.GET('/owner_ms/api/v1/renovation_advance/sales/details', params, (data) => {
            if (data)
                dispatch(savePitchData(data))
        }, (error) => {
            dispatch(errorFetchingPitchData(error))
        })
    }
}

export function saveLockLoanData(payload) {
    return {
        type: actionTypes.SAVE_LOCK_LOAN_DATA,
        payload: payload
    }
}

export function startLockingLoan() {
    return {
        type: actionTypes.START_LOCKING_LOAN,
    }
}

export function errorLoackingLoan(error) {
    return {
        type: actionTypes.ERROR_LOCKING_LOAN,
        payload: error
    }
}

export function lockLoan(params) {
    return (dispatch) => {
        dispatch(startLockingLoan());
        RESTApi.GET('/owner_ms/api/v1/renovation_advance/sales/lock_loan', params, (data) => {
            if (data)
                dispatch(saveLockLoanData(data))
        }, (error) => {
            dispatch(errorLoackingLoan(error))
        })
    }
}


export function uploadDocument(params, data) {
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
    };
    return (dispatch) => {
        dispatch(startUploadingDocument(params));
        RESTApi.POST('/owner_ms/api/v1/renovation_advance/sales/upload_doc', params, data, (data) => {
            if (data)
                dispatch(saveUploadedDocument(data, params))
        }, (error) => {
            dispatch(errorUploadingDocument(error, params))
        }, headers)
    }
}

export function saveUploadedDocument(payload, params) {
    return {
        type: actionTypes.SAVE_UPLOADED_DOCUMENT,
        payload: payload,
        params: params
    }
}

export function startUploadingDocument(params) {
    return {
        type: actionTypes.START_UPLOADING_DOCUMENT,
        params: params
    }
}

export function errorUploadingDocument(error, params) {
    return {
        type: actionTypes.ERROR_UPLOADING_DOCUMENT,
        payload: error,
        params: params
    }
}

export function removeDocument(params) {
    RESTApi.POST('/owner_ms/api/v1/renovation_advance/sales/remove_doc', params, null, (data) => { }, (error) => { });
    return {
        type: actionTypes.REMOVE_DOCUMENT,
        params: params
    }
}

export function submitDetails(params) {
    return (dispatch) => {
        dispatch(startSubmittingDetails());
        RESTApi.GET('/owner_ms/api/v1/renovation_advance/sales/submit_doc', params, (data) => {
            if (data)
                dispatch(saveSubmittedDetails(data))
        }, (error) => {
            dispatch(errorSubmittingDetails(error))
        })
    }
}

export function saveSubmittedDetails(payload) {
    return {
        type: actionTypes.SAVE_SUBMITTED_DETAILS,
        payload: payload
    }
}

export function startSubmittingDetails() {
    return {
        type: actionTypes.START_SUBMITTING_DETAILS
    }
}

export function errorSubmittingDetails(error) {
    return {
        type: actionTypes.ERROR_SUBMITTING_DETAILS,
        payload: error
    }
}

export function fetchContract(params) {
    return (dispatch) => {
        dispatch(startFetchingContract());
        RESTApi.GET('/owner_ms/api/v1/renovation_advance/sales/get_contract', params, (data) => {
            if (data)
                dispatch(saveFetchedContract(data))
        }, (error) => {
            dispatch(errorFetchingContract(error))
        })
    }
}

export function saveFetchedContract(payload) {
    return {
        type: actionTypes.SAVE_FETCHED_CONTRACT,
        payload: payload
    }
}

export function startFetchingContract() {
    return {
        type: actionTypes.START_FETCHING_CONTRACT
    }
}

export function errorFetchingContract(error) {
    return {
        type: actionTypes.ERROR_FETCHING_CONTRACT,
        payload: error
    }
}