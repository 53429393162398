import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';
import { logger } from 'redux-logger';

const middlewares = [thunk];

if(process.env.NODE_ENV === 'development')
    middlewares.push(logger);

// const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         trace: true,
//         traceLimit: 25
//         // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//     })
//     : compose;

// const enhancer = composeEnhancers(
//     applyMiddleware(...middlewares),
//     // other store enhancers if any
// );

export default function configureStore() {
    return createStore(
        rootReducer,
        // enhancer
        applyMiddleware(...middlewares)
    );
}