import moment from 'moment';

export const getDisabledDateOfPreviousMonth = current => {
	return current < moment().startOf('month');
};

export const DateDiff = (start, end) => {
	const startDate = moment(start, 'YYYY-MM-DD');
	const endDate = moment(end, 'YYYY-MM-DD');
	const duration = moment.duration(endDate.diff(startDate));
	const days = duration.asDays();
	return days;
};

export const epochToDate = (value, format) => {
  try {
    return value === null ? "" : moment(value).format(format);
	} catch (e) {
		return '';
	}
};
