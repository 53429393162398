export const WebsiteState = {
  websiteData: {
    data: {},
    isFetching: false,
    error: false
  },
  websiteSubscription: {
    data: {},
    isFetching: false,
    error: false,
  }
}