import React from 'react';
import './style.css';
import Spinner from '../../components/Spinner';
import TryAgainLater from '../../components/TryAgainLater';
import ErrorToast from '../../components/ErrorToast';
import * as images from '../../assets/images/whatsapp.png'

export default class WhatsappOptinRender extends React.PureComponent {
    render() {
        const { whatsappOptin, isFetching, errorToast } = this.props;
        return (
            <div className="Container">
                {isFetching ?
                    <div className="SpinnerContainer">
                        <Spinner />
                    </div>
                    :
                    whatsappOptin ?
                        <div className="SpinnerContainer" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
                            <ThanksPage />
                        </div>
                        :
                        <TryAgainLater />
                }
                <ErrorToast error={errorToast} />
            </div>
        );
    }
}

class ThanksPage extends React.PureComponent {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#fff', padding: '15px 15px', borderRadius: 6, borderColor: 'rgba(0,0,0,0.06)', borderWidth: 1, fontFamily: 'Brown-Bold', margin: 10 }}>
                    <img src={images} alt="" style={{ width: 70 }}/>
                    <h2 style={{ fontFamily: 'Brown-Bold' }}>Thank You</h2>
                    <h3>for opting to our WhatsApp Service.</h3>
                </div>
            </div>
        );
    }
}